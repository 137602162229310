import React, { useEffect, useState } from "react";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import RetryStackUI from "../molecules/retryStackUI";
import UpdateRetryInfoPageUI from "./updateRetryInfoPageUI";
import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";

const CustomerRetryInfoPageUI = ({
  submittedImage,
  handleSubmit,
  personalInfo,
  businessInfo,
  owners,
  handleItemClick,
}) => {
  return (
    <VerificationPageContainer
  
      handleSubmit={handleSubmit}
      title={"Customer Retry Information"}
      description={
        "Recheck your information and make sure to add in your full social and other information."
      }
      currentStep={5}
    >
      <RetryStackUI
        data={{ personalInfo, businessInfo, owners }}
        onItemClick={handleItemClick}
      />
    </VerificationPageContainer>
  );
};

export default CustomerRetryInfoPageUI;
