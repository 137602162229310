import React from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GeneralButton from '../../../../../components/atoms/buttons/GeneralButton';
import StepIndicator from '../../../../../components/molecules/progress/StepIndicator';

const MiddleCardContent = ({
    currentStep,
    handleSubmit,
    title,
    description,
    onBack,
    backArrow,
    disabled,
    button,
    children
}) => {
    const isBelow700px = useMediaQuery("(max-width:700px)");

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                p: isBelow700px ? 1 : 4,
                mx: "auto",
                width: "100%"
            }}
        >
            {/* Header Section */}
            <Box sx={{ width: '100%', textAlign: 'center', mb: 2, position: 'relative' }}>
                {backArrow && (
                    <IconButton
                        aria-label="back"
                        onClick={onBack}
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography 
                  variant="h2" 
                  component="div" 
                  sx={{ 
                    color: '#1EC674', // Accent color for the subheader
                    fontWeight: 600,  // Slightly bold for emphasis
                    mb: 2,            // Margin bottom to separate from the main header
                  }}
                >
                  Clavaa
                </Typography>

                <Typography 
                  variant="h1" 
                  gutterBottom 
                  sx={{
                    color: '#000',     // Pure black for the main header
                    fontWeight: 700,   // Extra bold for the main title
                    lineHeight: 1.2,   // Tighter line height for a more impactful look
                    mb: 2,             // Slightly more space below the main title
                  }}
                >
                  {title}
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,   // A bit lighter than the main header
                    color: '#666',     // Softer grey to contrast with the black title
                    mb: 1,             // Ensure enough space after the subheader
                    lineHeight: 1.4,   // Increase readability for a longer description
                    textAlign: 'center', // Optional: center align for a more balanced look
                  }}
                >
                  {description}
                </Typography>
            </Box>

            {/* Render children for form fields or other content */}
            <Box sx={{ maxWidth: 500, width: '100%', mx: 'auto', textAlign: 'center' }}>
                {children}
            </Box>
            {button && (
  <GeneralButton
    label="Submit"
    type="contained"
    expandable={true}
    center={true}
    sx={{ mt: 3 }}
    onClick={handleSubmit}
    disabled={disabled}
  />
)}

            {/* Step Indicator */}
            <StepIndicator currentStep={currentStep} sx={{ mt: 4 }} />
        </Grid>
    );
};

export default MiddleCardContent;
