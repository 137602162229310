import React from "react";
import CashBackPageUI from '../../ui/organisms/cashBackPageUI';
import CashBackLogic from "../../functional/organisms/cashBackLogic";

const CashbackContainer = () => {
  const {currentCashback, cashbackHistory, isUpdateModalOpen, isHistoryModalOpen, handleOpenUpdateModal, handleOpenHistoryModal, handleCloseUpdateModal, handleCloseHistoryModal, handleCashbackChange, handleUpdateCashbackSubmit, handleClose, cashbackValue}  = CashBackLogic()

  return (
   <CashBackPageUI
    currentCashback={currentCashback}
    cashbackHistory={cashbackHistory}
    isUpdateModalOpen={isUpdateModalOpen}
    isHistoryModalOpen={isHistoryModalOpen}
    handleOpenUpdateModal={handleOpenUpdateModal}
    handleCloseUpdateModal={handleCloseUpdateModal}
    handleOpenHistoryModal={handleOpenHistoryModal}
    handleCloseHistoryModal={handleCloseHistoryModal}
    handleCashbackChange={handleCashbackChange} 
    handleUpdateCashbackSubmit={handleUpdateCashbackSubmit} 
    handleClose={handleClose}
    cashbackValue={cashbackValue}
   />
  );
};

export default CashbackContainer;
