import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Box } from "@mui/material";

import DynamicForm from "../../../../../components/atoms/inputs/dynamic";

const PersonalInfoFormInputsUI = ({
  fieldConfig,
  personalInfo,
  handleInputChange,
  errors,
}) => {
  return (
      <Box
        sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}
      >
        <DynamicForm
          fieldConfig={fieldConfig}
          formData={personalInfo}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={personalInfo.isOwner || false}
              onChange={handleInputChange}
              name="isOwner"
            />
          }
          label="Check here if the controller owns at least 25% of the entity."
        />
      </Box>
  );
};

export default PersonalInfoFormInputsUI;
