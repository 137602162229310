import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import useSuspended from "../../functional/page/useSuspended";
import SuspendedPagePanelUIContainer from "../organisms/suspendedPagePanelUIContainer";

const SuspendedPage = () => {
  const {
    submittedImage,
    title,
    description,
    currentStep,
    customerServiceEmail,
  } = useSuspended();
  return (
    <VerificationPageContainer
     
      title={title}
      description={description}
      currentStep={currentStep}
    >
      <SuspendedPagePanelUIContainer
        customerServiceEmail={customerServiceEmail}
      />
    </VerificationPageContainer>
  );
};

export default SuspendedPage;
