import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import {
  activateLoader,
  deactivateLoader,
} from "../../features/loading/loadingSlice";
import { showAlert } from "../../features/alerts/alertSlice";
import { masterRequest } from "../apiStructures/masterRequest";
import { generateValidator } from "../validators/generateValidator";
import { showDialog } from "../../features/dialog/dialogSlice";

/**
 * Custom hook to handle API fetch with loading and error handling.
 *
 * @returns {Function} A function to call the API with feedback handling.
 *
 * Usage:
 * const fetchFeedback = useFullFetchFeedback();
 * fetchFeedback({
 *   requestType: 'get', // 'get', 'post', 'postform', 'delete'
 *   endpoint: '/api/endpoint',
 *   data: { key: value },
 *   loaderType: 'pageLoader',
 *   loaderMessage: 'Loading...',
 *   onSuccess: (response) => { console.log(response); },
 *   onError: (error) => { console.error(error); },
 *   validate: (data) => { return {}; }, // validation function
 *   setError: (errors) => { console.error(errors); }, // function to handle validation errors
 *   auth: true, // if true, include authorization header
 * });
 */
const useFullFetchFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async ({
    requestType,
    endpoint,
    data,
    loaderType = "pageLoader",
    loaderMessage = "Loading...",
    successMessage= null,
    onSuccess = () => {},
    onError = () => {},
    validationRules = [],
    setError = () => {},
    auth = true, 
  }) => {
    console.log("here")

    if (validationRules.length !== 0) {

      const validate = generateValidator(validationRules);
    
      const errors = validate(data);
   
      if (Object.keys(errors).length !== 0) {
        console.log(  errors)
        setError(errors);
        onError(errors);
        return;
      }
    }
   
    
    
    
    let authKey = null;
    console.log("auth:", auth);  // logs the value of 'auth'
    
    if (auth) {
      // Log the entire localStorage to see its contents
      console.log("localStorage content:", localStorage);
    
      // Try retrieving the token from localStorage
      authKey = localStorage.getItem("token"); 
      console.log("authKey retrieved from localStorage:", authKey);  // log authKey after retrieval
    
      if (!authKey) {
        // If authKey is null, log an appropriate message and redirect the user
        console.log("No authKey found, redirecting to sign-in.");
        dispatch(showAlert({ type: "warning", message: "You must log in to continue." }));
        navigate("/sign-in"); // Redirect the user to the login page
        return;
      }
    }
    
    // If authKey exists, continue the logic
    console.log("Final authKey:", authKey);
    dispatch(activateLoader({ loaderType, loaderMessage }));
    
    try {
      const response = await masterRequest(endpoint, requestType, data, authKey);
console.log(response)
      dispatch(deactivateLoader(loaderType));

    


      if (response.error) {
        // Check if response.error contains an object with an error key
        if (response.error.error) {
          const errorMessage = response.error.error;
          onError(response.error);
          dispatch(showAlert({ type: "error", message: errorMessage }));
          
        } else {
          setError(response.error);
          onError(response.error);
        }
      } else {
        if (successMessage) {
          dispatch(
            showDialog({ dialogType: "success", message: successMessage })
          );
        }
        onSuccess(response);
      }
    } catch (err) {
      dispatch(deactivateLoader(loaderType));
      onError(err);
    }
  };
};

export default useFullFetchFeedback;
