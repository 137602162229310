import React from "react";
import {
  CardContent,
  Typography,
  Divider,
  Paper,
} from "@mui/material";
import HelpInfoDailogButton from "../../../../components/atoms/dailog/helpInfoDailogButton";

const InfoBoxUI = ({
  title,
  value,
  info,
  activeInfoDailog,
  handleInfoDailog,
  infoDetails,
}) => {
  return (
    <Paper
      sx={{
        width: 240,
        height: 125,
        bgcolor: "#F0F0F0",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
      }}
      elevation={3}
    >
      <CardContent
        sx={{
          padding: "16px !important",
          "&:last-child": { paddingBottom: "16px !important" },
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: "#333", // Use a darker color for text for contrast
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "0.875rem",
          }}
        >
          {title}
        </Typography>
        <Divider sx={{ my: 1, bgcolor: "rgba(0,0,0,0.12)" }} />{" "}
        {/* Lighter divider */}
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "#333", // Consistent text color
            fontWeight: "600",
            fontSize: "2rem",
          }}
        >
          {value}
        </Typography>
      </CardContent>

      {/* <InfoIconButton title={title} info={info} /> */}
      <HelpInfoDailogButton
        open={activeInfoDailog}
        title={infoDetails?.title}
        info={infoDetails?.info}
        handleInfoDailog={handleInfoDailog}
      />
    </Paper>
  );
};

export default InfoBoxUI;
