import codeImage from "../../../../../images/code.png";
import useEmailCodeInputs from "../organisms/useEmailCodeInputs";

const useEmailCode = () => {
  const title = "Verification Code";
  const description =
    "Please enter the code sent to your email for security purposes.";
  const currentStep = 2;

  const { code, setCode, error, handleSubmit } = useEmailCodeInputs();

  return {
    codeImage,
    handleSubmit,
    code,
    setCode,
    error,
    title,
    description,
    currentStep,
  };
};

export default useEmailCode;
