import { Typography } from "@mui/material";

const PendingPagePanelUI = () => {
return (
  <Typography variant="body1" sx={{ mb: 2 }}>
    Your merchant information is currently under review with Dwolla, our trusted
    payment service provider. We're working diligently to verify your details.
    Please check the verification status to stay updated on the progress.
  </Typography>
);
}

export default PendingPagePanelUI