import { useState } from "react";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../../features/dialog/dialogSlice";
import documentUploadInstructions from "../constants/documentUploadInstructions";

const useUploadField = ({ onFileSelect }) => {
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState("No document uploaded");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (onFileSelect) {
          onFileSelect(reader.result); // Pass the base64 encoded data URL
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onClick = () => {
    dispatch(
      showDialog({
        dialogType: "info",
        message: documentUploadInstructions,
        title: "Upload Guidelines",
      })
    );
  };

  return {
    handleFileChange,
    onClick,
    documentName,
  };
};

export default useUploadField;
