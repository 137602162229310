import { useEffect, useState } from "react";
import validateOwnerDetails from "../helpers/owner/validate";
import { updateOwnersList } from "../helpers/owner/saveOwner";
import personalInfoImage from "../../../../../images/personal.png";
import { handleInputChange } from "../../../../../functions/general/setData";

const useOwnerInfoForm = ({ activeOwner, setOwners, handleSubmit }) => {
  const [owner, setOwner] = useState({
    id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    ssn: "",
    address: { address: "", city: "", state: "", zip_code: "" },
  });
  const [errors, setError] = useState("");

  useEffect(() => {
    if (activeOwner) {
      setOwner((prevOwner) => ({
        ...activeOwner,
      }));
    }
  }, [activeOwner]);

  const inputConfigs = [
    {
      type: "text",
      label: "First Name",
      name: "first_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      label: "Last Name",
      name: "last_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "date",
      label: "Date of Birth",
      name: "date_of_birth",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "ssn",
      label: "SSN",
      name: "ssn",
      grid: { xs: 12, sm: 6 },
      props: { fullSSN: true },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
  ];

  const onClick = () => {
    const errors = validateOwnerDetails(owner);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      return;
    }
    setOwners((prevState) => updateOwnersList(prevState, owner));

    handleSubmit();
  };

  return {
    personalInfoImage,
    onClick,
    inputConfigs,
    owner,
    handleInputChange,
    setOwner,
    errors,
  };
};

export default useOwnerInfoForm;
