import accountImage from "../../../../../images/account.png";
import { handleInputChange } from "../../../../../functions/general/setData";
import useAccountInfoInputs from "../organisms/useAccountInfoInputs";

const useAccountInfo = () => {
  const title = "Account Information";
  const description = "Enter your username and password to proceed.";
  const currentStep = 3;

  const { handleSubmit, navigate, accountInfo, setAccountInfo, error } =
    useAccountInfoInputs();

  return {
    accountImage,
    handleSubmit,
    navigate,
    accountInfo,
    handleInputChange,
    setAccountInfo,
    error,
    title,
    description,
    currentStep,
  };
};

export default useAccountInfo;
