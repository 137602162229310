import {
    Dialog,
    DialogContent,
    Typography,
    Box,
    DialogTitle,
    IconButton,
  } from "@mui/material";
  import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
  import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ConnectingBankAccountUI = ({dots, open, moveUpDown}) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="connecting-account-title"
            sx={{
                "& .MuiDialog-paper": {
                borderRadius: 2,
                maxWidth: 400,
                p: 3,
                boxShadow: "0 3px 15px rgba(0,0,0,0.2)",
                },
            }}
            >
            <DialogTitle id="connecting-account-title" sx={{ textAlign: "center" }}>
                Connecting your account to Clavaa
            </DialogTitle>
            <DialogContent>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    height: 200,
                }}
                >
                <Typography
                    variant="h4"
                    component="div"
                    sx={{ color: "#44C952", fontWeight: "medium" }}
                >
                    Clavaa
                </Typography>
                <IconButton
                    sx={{
                    color: "#44C952",
                    animation: `${moveUpDown} 2s ease-in-out infinite`,
                    fontSize: 50,
                    }}
                    aria-label="animated arrow indicating data movement"
                >
                    <ArrowDownwardIcon />
                </IconButton>
                <AccountBalanceIcon sx={{ fontSize: 60, color: "#44C952", mt: 2 }} />
                <Typography
                    component="div"
                    sx={{
                    position: 'absolute',
                    bottom: 2,
                    fontSize: '1rem',
                    }}
                >
                    Connecting{dots}
                </Typography>
                </Box>
            </DialogContent>
            </Dialog>
    )
};

export default ConnectingBankAccountUI; 