import { useState } from "react";

const useOwnerApplication = ({ controllerIndex, owners, setOwners }) => {
  const [showForm, setShowForm] = useState("");
  const [activeOwner, setActiveOwner] = useState("");

  const handleAdd = () => {
    setActiveOwner("");
    setShowForm(true);
  };

  const handleOwnerClick = (id) => {
    // Prevent selecting the controller as the active owner for editing
    if (id === controllerIndex) {
      return; // Early return to stop the function if trying to edit the controller
    }

    const owner = owners.find((owner) => owner.id === id);
    if (owner) {
      setActiveOwner({ ...owner });
      setShowForm(true);
    }
  };

  const handleDelete = (id) => {
    // Prevent deleting the controller
    if (id === controllerIndex) {
      return; // Early return to stop the function if trying to delete the controller
    }
    // Proceed with deletion if not the controller
    setOwners((currentOwners) =>
      currentOwners.filter((owner) => owner.id !== id)
    );
  };

  return {
    showForm,
    activeOwner,
    setShowForm,
    handleAdd,
    handleDelete,
    handleOwnerClick,
  };
};

export default useOwnerApplication;
