import submittedImage from "../../../../../../images/done.png";

const usePending = () => {
  const title = "Verification Pending";
  const description = "We just need to go over some small details.";
  const currentStep = 5;

  return {
    submittedImage,
    title,
    description,
    currentStep,
  };
};

export default usePending;
