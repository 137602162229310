import AccountInfoPageInputsUI from "../../ui/organisms/accountInfoPageInputsUI";

const AccountInfoPageInputsContainer = ({
  accountInfo,
  handleInputChange,
  error,
  setAccountInfo,
}) => {
  return (
    <AccountInfoPageInputsUI
      accountInfo={accountInfo}
      handleInputChange={handleInputChange}
      error={error}
      setAccountInfo={setAccountInfo}
    />
  );
};

export default AccountInfoPageInputsContainer;
