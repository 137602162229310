import React, { useEffect, useState } from "react";
import UpdateRetryInfoPageUIContainer from "../organisms/updateRetryInfoPageUIContainer";
import CustomerRetryInfoPageUIContainer from "../organisms/customerRetryInfoPageUIContainer";
import useCustomerRetry from "../../functional/page/useCustomerRetry";

const CustomerRetryInfoPage = () => {
  const {
    submittedImage,

    selectedRetryData,
    setSelectedRetryData,
    customerRetryInfoHandleSubmit,
    personalInfo,
    businessInfo,
    owners,
    customerRetryInfoHandleItemClick,

    dialogOpen,
    updateRetryInfoHandleItemClick,
    setDialogOpen,
    dialogData,
    formatValue,

    changeDataDialogHandleSubmit,
    formData,
    formatAddress,
    handleInputChange,
  } = useCustomerRetry();
  if (selectedRetryData) {
    return (
      <UpdateRetryInfoPageUIContainer
        selectedRetryData={selectedRetryData}
        navigateBackToController={() => {
          setSelectedRetryData(null);
        }}
        dialogOpen={dialogOpen}
        handleItemClick={updateRetryInfoHandleItemClick}
        setDialogOpen={setDialogOpen}
        dialogData={dialogData}
        submittedImage={submittedImage}
        formatValue={formatValue}
        handleSubmit={changeDataDialogHandleSubmit}
        formData={formData}
        formatAddress={formatAddress}
        handleInputChange={handleInputChange}
      />
    );
  }
  return (
    <CustomerRetryInfoPageUIContainer
      submittedImage={submittedImage}
      handleSubmit={customerRetryInfoHandleSubmit}
      personalInfo={personalInfo}
      businessInfo={businessInfo}
      owners={owners}
      handleItemClick={customerRetryInfoHandleItemClick}
    />
  );
};

export default CustomerRetryInfoPage;
