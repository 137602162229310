import submittedImage from "../../../../../../images/done.png";
const useSuspended = () => {
  const customerServiceEmail = "support@yourcompany.com"; // Update with your actual customer service email
  const title = "Verification Failed";
  const description = "Account verifcation has failed.";
  const currentStep = 5;

  return {
    submittedImage,
    customerServiceEmail,
    title,
    description,
    currentStep,
  };
};

export default useSuspended;
