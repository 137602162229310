import { Grid, Link, Typography } from "@mui/material";
import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";

const EmailPageInputsUI = ({
  email,
  setEmail,
  error,
  handleSignIn,
  linkStyle,
}) => {
  return (
    <>
      <GeneralInput
        type={"text"}
        label="Email Address"
        name={"email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!error}
        errorMessageAndhelp={error.email || ""}
      />
     
    </>
  );
};

export default EmailPageInputsUI;
