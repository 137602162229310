import React, { useEffect, useState } from "react";


import PersonalInfoFormInputsUI from "../molecules/personalInfoFormInputsUI";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

const PersonalInfoFormUI = ({

  handleSubmit,
  fieldConfig,
  personalInfo,
  handleInputChange,
  errors,
}) => {
  return (
    <SignUpPageContainer
     
      handleSubmit={handleSubmit}
      title={"Controllers Details"}
      description={"Please fill in the businesses controllers details."}
      currentStep={3}
    >
      <PersonalInfoFormInputsUI
        fieldConfig={fieldConfig}
        personalInfo={personalInfo}
        handleInputChange={handleInputChange}
        errors={errors}
      />
    </SignUpPageContainer>
  );
};

export default PersonalInfoFormUI;
