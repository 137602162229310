import businessInfoImage from "../../../../../images/business.png";
import { handleInputChange } from "../../../../../functions/general/setData";
import useBusinessInfoInputs from "../organisms/useBusinessInfoInputs";

const useBusinessInfo = () => {
  const title = "Business Details";
  const description = "Please fill in the businesses details.";
  const currentStep = 4;

  const { handleSubmit, fieldConfig, businessInfo, setBusinessInfo, errors } =
    useBusinessInfoInputs();

  return {
    businessInfoImage,
    handleSubmit,
    fieldConfig,
    businessInfo,
    handleInputChange,
    setBusinessInfo,
    errors,
    title,
    description,
    currentStep,
  };
};

export default useBusinessInfo;
