// Clavaa Terms
export const clavaaTerms = {
    title: "Clavaa Terms of Service",
  
    info: "Clavaa's services include financial management tools and access to third-party platforms."
  };
  
  // Dwolla Terms
  export const dwollaTerms = {
    title: "Dwolla Terms of Service",
    subtitle: "Dwolla requires you to agree to their terms and conditions.",
    info: "Dwolla handles the payment processing and financial transfers for Clavaa."
  };
  