import BankPageUI from './../../ui/organisims/bankPageUI';
import BankLogic from './../../functional/organisms/bank';

const BankPageContainer = () => {
    const {
        bankData,
        dots,
        moveUpDown,
        hasBankAccount,
        pendingBalance,
        openModal,
        activeInfoDailog,
        activeCardInfoDailog,
        handleAccountClick,
        handleInfoDailog
    } = BankLogic();

    return (
        <>
            <BankPageUI
                bankData={bankData}
                dots={dots}
                moveUpDown={moveUpDown}
                hasBankAccount={hasBankAccount}
                pendingBalance={pendingBalance}
                activeInfoDailog={activeInfoDailog}
                openModal={openModal}
                activeCardInfoDailog={activeCardInfoDailog}
                handleAccountClick={handleAccountClick}
                handleInfoDailog={handleInfoDailog}
            />
        </>
    )
}

export default BankPageContainer