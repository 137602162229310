import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import usePending from "../../functional/page/usePending";
import PendingPagePanelUIContainer from "../organisms/pendingPagePanelUIContainer";

const PendingPage = () => {
  const { submittedImage, title, description, currentStep } = usePending();
  return (
    <VerificationPageContainer
  
      title={title}
      description={description}
      currentStep={currentStep}
    >
      <PendingPagePanelUIContainer />
    </VerificationPageContainer>
  );
};

export default PendingPage;
