import React from "react";
import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";



const CashbackCardUI = ({ onViewHistory, onUpdateCashback, currentCashback }) => {
  return (
    <Card sx={{ minWidth: 575, margin: 2, padding: 2, textAlign: "center" }}>
      <CardContent>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Your store is currently offering:
        </Typography>
        <Typography
          variant="h3"
          component="div"
          sx={{ fontWeight: "bold", mb: 1.5 }}
        >
          {currentCashback}%
        </Typography>
        <Typography sx={{ mb: 1 }} color="text.secondary">
          Cashback on All Clavaa Purchases
        </Typography>

        <Typography variant="body2">
          Rewards are redeemable on return visits, fostering loyalty at no
          immediate cost.
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          margin: "auto",
          padding: "8px 0",
        }}
      >
        <Box
          sx={{
            width: "40%",
          }}
        >
          <GeneralButton
            label="Update Cashback Offer"
            onClick={onUpdateCashback}
            type="contained"
            expandable="true"
          />
          <GeneralButton
            label="View Cashback History"
            onClick={onViewHistory}
            type="outlined"
            expandable="true"
          />
        </Box>
      </CardActions>
    </Card>
  );
};

export default CashbackCardUI;
