import DocumentManagerUI from "../../ui/organisms/documentManagerUI";

const DocumentManagerUIContainer = ({
  submittedImage,
  onDocumentUpload,
  isAllDocumentsUploaded,
  documentList,
  setSelectedDocument,
}) => {
  return (
    <DocumentManagerUI
      submittedImage={submittedImage}
      onDocumentUpload={onDocumentUpload}
      isAllDocumentsUploaded={isAllDocumentsUploaded}
      documentList={documentList}
      setSelectedDocument={setSelectedDocument}
    />
  );
};

export default DocumentManagerUIContainer;
