import { useState, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCloudFetch } from "../../../../config";
import { setBankData } from "./../../../../features/banking/bankSlice";


const useInitializePlaid = () => {
  const [openModal, setOpenModal] = useState(false);
  const [linkToken, setLinkToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const BASE_URL = useCloudFetch
    ? "https://clavaav1.ue.r.appspot.com/api/"
    : "http://192.168.1.62:8000/api/";

  useEffect(() => {
    const fetchLinkToken = async () => {
      const token = localStorage.getItem("token");
      const storedLinkToken = localStorage.getItem("plaidLinkToken");
      const storedExpiry = localStorage.getItem("plaidLinkTokenExpiry");
      const now = new Date();

      if (storedLinkToken && storedExpiry && new Date(storedExpiry) > now) {
        setLinkToken(storedLinkToken);
        return;
      }

      // Fetch a new link token if there is none stored or it has expired
      const response = await fetch(
        `${BASE_URL}merchant/token/create/browser/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 2); // Set expiry for 2 hours
        localStorage.setItem("plaidLinkToken", data.link_token);
        localStorage.setItem("plaidLinkTokenExpiry", expiryDate.toISOString());
        setLinkToken(data.link_token);
      } else {
        console.error("Failed to get link token:", data);
      }
    };

    fetchLinkToken();
  }, [BASE_URL]);

  const onSuccess = async (public_token, metadata) => {
    setOpenModal(true);
    const token = localStorage.getItem("token");

    const exchangeResponse = await fetch(
      `${BASE_URL}merchant/token/exchange/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          public_token: public_token,
          metadata: metadata,
        }),
      }
    );

    if (exchangeResponse.ok) {
      const accountData = await exchangeResponse.json();
      dispatch(setBankData(accountData));

      setOpenModal(false);
      navigate(-1);
    } else {
      console.error(
        "Failed to exchange public token:",
        await exchangeResponse.json()
      );
    }
  };

  const onExit = () => {
    // Handle the case when the user exits the Plaid flow
    navigate(-1);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit,
  });

  return { open, ready, openModal };
};

export default useInitializePlaid;
