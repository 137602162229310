import { Chip, ListItem, ListItemText } from "@mui/material";

const LocationDetailUI = ({ location, handleLocationClick }) => {
  return (
    <ListItem
      key={location.id}
      button
      onClick={() => handleLocationClick(location)}
      secondaryAction={
        <Chip
          sx={{ width: 90 }}
          variant="outlined"
          label={location.status}
          size="small"
          color={
            location.status === "Verified"
              ? "success"
              : location.status === "Pending"
                ? "warning"
                : "default"
          }
        />
      }
    >
      <ListItemText primary={location.name} secondary={location.address} />
    </ListItem>
  );
};

export default LocationDetailUI;
