import React from "react";
import { Grid } from "@mui/material";
import InfoBox from "../atoms/tabUI"; // Your custom MUI component

const TabsUI = ({
  infoBoxes,
  activeInfoDailog,
  handleInfoDailog,
  info: infoDetails,
  activeIndex,
}) => {
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="stretch">
      {infoBoxes.map(({ title, value, info, isGreen }, index) => (
        <Grid
          key={index}
          item
          display="flex"
          justifyContent="center"
          xs={12}
          sm={6}
          lg={3}
        >
          <InfoBox
            title={title}
            value={value}
            info={info}
            isGreen={isGreen}
            activeInfoDailog={activeInfoDailog && index === activeIndex}
            handleInfoDailog={() => handleInfoDailog(index)}
            infoDetails={infoDetails}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TabsUI;
