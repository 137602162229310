// AccountInfoPageUI.js
import React from "react";
import { Box } from "@mui/material";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import PageLayout from "../../../../components/pageStructures/authPages/master";


const AccountInfoPageUI = ({
  accountInfo,
  setAccountInfo,
  error,

  children,
}) => {
  return (
    <>
      <Box sx={{ display: "grid", gap: 2 }}>
        <GeneralInput
          type={"text"}
          label="Username"
          name={"username"}
          value={accountInfo.username}
          onChange={(e) =>
            setAccountInfo({ ...accountInfo, username: e.target.value })
          }
          error={error?.username !== undefined && error?.username !== null}
          errorMessageAndhelp={error.username || ""}
        />
        <GeneralInput
          type={"password"} // Changed to "password" type for the password field
          label="Password"
          name={"password"}
          value={accountInfo.password}
          onChange={(e) =>
            setAccountInfo({ ...accountInfo, password: e.target.value })
          }
          error={!!error.password}
          errorMessageAndhelp={error.password || ""}
        />
      </Box>
      {children}
   </>
  );
};

export default AccountInfoPageUI;
