import { Box, Typography } from '@mui/material';
import RequirementIcon from '../../../../components/atoms/icons/RequirementIcon';

const StatusUI = ({title, statusName, status, handleIconClick, requirements}) => {
    return (
        <Box>
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                sx={{ fontWeight: "medium", fontSize: "1.1rem" }}
            >
                {title}
            </Typography>
            <Box
                onClick={() => handleIconClick(statusName, status)}
                sx={{ cursor: 'pointer', width: 'fit-content', margin: "auto"}}
            >
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    align="center"
                    color="text.secondary"
                    sx={{ fontWeight: "medium", fontSize: "1.5rem" }}
                >
                    {status ? "Active" : "Inactive"}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 4 }}>
                {Object.entries(requirements).map(([key, value]) => (
                    <Box
                        key={key}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <RequirementIcon completed={value} name={key} handleIconClick={handleIconClick} />
                        <Typography
                            variant="caption"
                            sx={{ mt: 1, textTransform: "capitalize" }}
                        >
                            {key.split("_").join(" ")}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}


export default StatusUI;