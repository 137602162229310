import UpdateRetryInfoPageUI from "../../ui/organisms/updateRetryInfoPageUI";

const UpdateRetryInfoPageUIContainer = ({
  selectedRetryData,
  navigateBackToController,
  dialogOpen,
  handleItemClick,
  setDialogOpen,
  dialogData,
  submittedImage,
  formatValue,
  handleSubmit,
  formData,
  formatAddress,
  handleInputChange,
}) => {
  return (
    <UpdateRetryInfoPageUI
      selectedRetryData={selectedRetryData}
      navigateBackToController={navigateBackToController}
      dialogOpen={dialogOpen}
      handleItemClick={handleItemClick}
      setDialogOpen={setDialogOpen}
      dialogData={dialogData}
      submittedImage={submittedImage}
      formatValue={formatValue}
      handleSubmit={handleSubmit}
      formData={formData}
      formatAddress={formatAddress}
      handleInputChange={handleInputChange}
    />
  );
};

export default UpdateRetryInfoPageUIContainer;
