import PersonalInfoFormUI from "../../ui/organisms/personalInfoFormUI";

const PersonalInfoFormUIContainer = ({
  personalInfoImage,
  handleSubmit,
  fieldConfig,
  personalInfo,
  handleInputChange,
  errors,
}) => {
  return (
    <PersonalInfoFormUI
      personalInfoImage={personalInfoImage}
      handleSubmit={handleSubmit}
      fieldConfig={fieldConfig}
      personalInfo={personalInfo}
      handleInputChange={handleInputChange}
      errors={errors}
    />
  );
};

export default PersonalInfoFormUIContainer;
