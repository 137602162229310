import { useSelector } from "react-redux";
import {
  selectTransactions,
  selectTransactionsLoading,
} from "../../../../features/transactions/transactionsSelectors";

const useTransactions = () => {
  // Fetch transactions and other relevant state
  const transactions = useSelector(selectTransactions);
  const loading = useSelector(selectTransactionsLoading);

  // Show only the first 5 transactions
  const displayedTransactions = transactions.slice(0, 4);

  return {
    displayedTransactions,
    loading,
  };
};

export default useTransactions;
