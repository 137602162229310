import { useDispatch, useSelector } from "react-redux";
import { selectDuration } from "../../../../features/dashboard/dashboardSelectors";
import { useState } from "react";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import {
  setDuration,
  updateDashboardMetrics,
} from "../../../../features/dashboard/dashboardSlice";

const useDataDuration = () => {
  const duration = useSelector(selectDuration);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmitDuration = async (newDuration) => {
    await fetchFeedback({
      endpoint: `merchant/tabs/data/?days=${newDuration}`,
      loaderType: "dialogueLoader",
      requestType: "GET",
      loaderMessage: "Updating duration...",
      successMessage: "Duration updated successfully!",
      onSuccess: (responseData) => {
        dispatch(updateDashboardMetrics(responseData));
        dispatch(setDuration(newDuration));
      },
    });
  };

  return {
    duration,
    setDialogOpen,
    dialogOpen,
    handleSubmitDuration,
  };
};

export default useDataDuration;
