import React, { useState } from "react";

import OwnerInfoFormUI from "./ownerInfoFormUI";
import OwnerManagerUI from "./ownerManagerUI";

// Accept the shared state and functions as props
const OwnerApplicationUI = ({
  owners,
  setOwners,
  navigateBackToController,
  controllerIndex,
  handleSubmit,
  showForm,
  activeOwner,
  setShowForm,
  handleAdd,
  handleDelete,
  handleOwnerClick,

  personalInfoImage,
  onClick,
  inputConfigs,
  owner,
  handleInputChange,
  setOwner,
  errors,
  handleDeleteClick,
}) => {
  return (
    <>
      {showForm ? (
        <OwnerInfoFormUI
          personalInfoImage={personalInfoImage}
          onClick={onClick}
          inputConfigs={inputConfigs}
          owner={owner}
          handleInputChange={handleInputChange}
          setOwner={setOwner}
          errors={errors}
          activeOwner={activeOwner}
          handleSubmit={() => {
            setShowForm(false);
          }}
          navigateBackToOwnerManager={() => {
            setShowForm(false);
          }}
          setOwners={setOwners}
        />
      ) : (
        <OwnerManagerUI
          owners={owners.map((owner) => ({
            id: owner.id,
            name: `${owner.first_name} ${owner.last_name}`,
          }))}
          onAdd={handleAdd}
          // onDelete={handleDelete}
          handleDeleteClick={handleDeleteClick}
          onOwnerClick={handleOwnerClick}
          navigateBackToController={navigateBackToController}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default OwnerApplicationUI;
