import { Box, Grid } from "@mui/material";
import RemainingBalanceUI from './../molecules/remainingBalanceUI';
import AddBankCardUI from './../molecules/addBankCardUI';
import BankCardUI from './../molecules/bankCardUI';
import ConnectingBankAccount from "../molecules/connectBankAccountUI"; // Assuming this is the modal component

const BankPageUI = ({
  bankData,
  dots,
  moveUpDown,
  hasBankAccount,
  pendingBalance,
  openModal,
  activeInfoDailog,
  activeCardInfoDailog,
  handleAccountClick,
  handleInfoDailog
}) => {

  return (
    <Box sx={{ flexGrow: 1, mt: 5, mb: 5 }}>
      <Grid
        container
        spacing={5} // Increased spacing between elements
        justifyContent="center" // Ensures items are centered
        alignItems="center" // Vertically aligns items in the center
      >
        {/* Remaining Balance */}
        <Grid item xs={12} md={5} display="flex" justifyContent="center">
          <RemainingBalanceUI
            pendingBalance={pendingBalance}
            activeInfoDailog={activeInfoDailog}
            handleInfoDailog={handleInfoDailog}
            activeCardInfoDailog={activeCardInfoDailog}
          />
        </Grid>

        {/* Bank Card or Add Bank Card */}
        <Grid item xs={12} md={5} display="flex" justifyContent="center">
          {hasBankAccount ? (
            <BankCardUI
              bankData={bankData}
              handleAccountClick={handleAccountClick}
              activeInfoDailog={activeInfoDailog}
              handleInfoDailog={handleInfoDailog}
              activeCardInfoDailog={activeCardInfoDailog}
            />
          ) : (
            <AddBankCardUI
              handleAccountClick={handleAccountClick}
              activeInfoDailog={activeInfoDailog}
              handleInfoDailog={handleInfoDailog}
              activeCardInfoDailog={activeCardInfoDailog}
            />
          )}
        </Grid>
      </Grid>

      {/* Modal for Connecting Bank Account - Outside of the grid layout */}
      {openModal && (
        <ConnectingBankAccount
          moveUpDown={moveUpDown}
          dots={dots}
          open={openModal}
        />
      )}
    </Box>
  );
};

export default BankPageUI;
