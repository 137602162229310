export const updateOwnersList = (owners, owner) => {
  const filteredOwners = owners.filter(
    (existingOwner) => existingOwner.id !== owner.id
  );

  const highestId =
    filteredOwners.length > 0
      ? filteredOwners.reduce(
          (maxId, owner) => Math.max(maxId, parseInt(owner.id, 10)),
          0
        )
      : 0;

  const newOwner = {
    ...owner,
    id: owner.id || highestId + 1,
  };

  return [...filteredOwners, newOwner];
};
