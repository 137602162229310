import React from 'react';
import MiddleCardContent from '../../UI/organism/verificationCard';
import AuthPage from '../../UI/pages/main';


const VerificationPageContainer = ({
  currentStep,
  handleSubmit,
  title ,
  description,
  onBack,
  backArrow,
  disabled,
  button= true,
  children
}) => {
  return (
    <AuthPage title={"Let's Get Your account Verified"}>

      <MiddleCardContent
        currentStep={currentStep}
        handleSubmit={handleSubmit}
        title={title}
        description={description}
        onBack={onBack}
        backArrow={backArrow}
        disabled={disabled}
        button={button}
      >
      
        {children}
      </MiddleCardContent>
    </AuthPage>
  );
};

export default VerificationPageContainer;
