import React from "react";

import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import DashboardPageContainer from "../organisms/dashboardPageContainer";
const DashboardPage = () => {
  return (
    <MainLayout>
      <DashboardPageContainer />
    </MainLayout>
  );
};

export default DashboardPage;
