
import { Box, Tab, Tabs } from '@mui/material';
// import LogoutPanelContainer from '../../combined/molecules/logoutPanelContainer';
// import AccountStatusPanelContainer from '../../combined/molecules/accountStatusPanelContainer';
// import ContactSupportPanelContainer from '../../combined/molecules/contactSupportPanelContainer'; 
import { Typography, Paper } from '@mui/material';
import AccountStatusPanelUI from '../../ui/molecules/accountStatusPanelUI';
import ContactSupportPanelUI from '../../ui/molecules/contactSupportPanelUI';
import LogoutPanelUI from '../molecules/logoutPanelUI';

const SettingsPanelUI = ({
      tabValue,
      requirements,
      profileStatus,
      isActive,
      dailogDetails,
      activeDailog,
      handleIconClick,
      handleChange,
      handleSignOut,
      handleDailogClose
}) => {
    return(
        <Paper sx={{
          maxWidth: 800,
          margin: 'auto',
          mt: 4,
          p: 2,
          bgcolor: "#F0F0F0",
        }}
        elevation={3}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
            Settings
          </Typography>
    
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="Settings tabs"
            centered // Center the tabs
            variant="fullWidth" // Use this variant to make tabs take up the full container width
          >
            <Tab label="Contact Support" value="1" />
            <Tab label="Account status" value="2" />
            <Tab label="Logout" value="3" />
          </Tabs>
        </Box>
    
          {tabValue === '1' && <ContactSupportPanelUI />}
          {tabValue === '3' && <LogoutPanelUI handleSignOut={handleSignOut}/>}
          {tabValue === '2' && <AccountStatusPanelUI requirements={requirements} profileStatus={profileStatus} isActive={isActive} handleIconClick={handleIconClick} dailogDetails={dailogDetails} activeDailog={activeDailog} handleDailogClose={handleDailogClose} />}
    
          {/* Future panels can be added here following the same pattern */}
        </Paper>
    )
}

export default SettingsPanelUI