import { Box } from "@mui/material";
import TransactionsTable from "../molecules/transactionTableUI";

const TransactionsTablePanel = ({ displayedTransactions, loading }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <TransactionsTable
        displayedTransactions={displayedTransactions}
        loading={loading}
      />
    </Box>
  );
};

export default TransactionsTablePanel;
