import CustomCard from './../../../../components/atoms/cards/customCard';   
import Typography from "@mui/material/Typography";
import HelpInfoDailogButton from './../../../../components/atoms/dailog/helpInfoDailogButton';

const RemainingBalanceUI = ({ 
  pendingBalance,
  handleInfoDailog,
  activeCardInfoDailog,
  activeInfoDailog,
 }) => {
  return (
    <CustomCard>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Pending Balance
      </Typography>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        ${pendingBalance}
      </Typography>
      <Typography variant="body1" sx={{ width: "50%", textAlign: "center" }}>
        This amount will be deposited into the merchant's account soon.
      </Typography>
      <HelpInfoDailogButton
        open={activeInfoDailog && activeCardInfoDailog === 'remainingBalance'}
        title={"More About Pending Balance"}
        info={
          "The pending balance represents funds from transactions that have been authorized but not yet settled. These funds are expected to be deposited into the merchant's account shortly, following the standard clearing process. This mechanism ensures transactions are valid and funds are properly allocated."
        }
        handleInfoDailog={() => {handleInfoDailog('remainingBalance')}}
      />
    </CustomCard>
  );
}

export default RemainingBalanceUI;