import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../../functions/apiFetchers/fullPageLoaders";
import { useDispatch } from "react-redux";
import { validateField } from "../../../../../../functions/validators/master";
import { showAlert } from "../../../../../../features/alerts/alertSlice";
import submittedImage from "../../../../../../images/done.png";

const useCustomerRetryInfo = () => {
  const location = useLocation();

  const {
    retry_controller_data,
    owner_retry_data,
    retry_business_data,
    ownerIndex,
  } = location.state || {};
  const [personalInfo, setPersonalInfo] = useState(retry_controller_data);
  const [businessInfo, setBusinessInfo] = useState(retry_business_data);
  const [owners, setOwners] = useState(owner_retry_data);
  const [updatedPersonalInfo, setUpdatedPersonalInfo] = useState({});
  const [updatedBusinessInfo, setUpdatedBusinessInfo] = useState({});
  const [updatedOwners, setUpdatedOwners] = useState([]);
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();
  const [selectedRetryData, setSelectedRetryData] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    const regularSsn = personalInfo?.Ssn?.ssn;

    const errorRegular = validateField("fullSsn", regularSsn);

    if (regularSsn && errorRegular) {
      const errorMessage = errorRegular;
      dispatch(
        showAlert({
          type: "error",
          message: "We need your full social to verify your identity.",
        })
      );
      return;
    }

    await fetchFeedback({
      endpoint: "merchant/retry-info/",
      requestType: "POST",
      data: {
        retry_controller_data: updatedPersonalInfo,
        retry_business_data: updatedBusinessInfo,
        owner_retry_data: updatedOwners,
      },
      loaderMessage: "Updating the new information...",
      // On success, navigate to the code-verification page
      onSuccess: (responseData) => {
        navigate("/status");
      },
      onError: (error) => {},
    });
  };

  const handleItemClick = ({ category, item, id }) => {
    let oldSetData, newSetData;

    // Determine which state and update functions to use based on the category.
    // This helps in organizing and updating the state relevant to the selected category.
    switch (category) {
      case "owners":
        oldSetData = setOwners;
        newSetData = setUpdatedOwners;
        break;
      case "personalInfo":
        oldSetData = setPersonalInfo;
        newSetData = setUpdatedPersonalInfo;
        break;
      case "businessInfo":
        oldSetData = setBusinessInfo;
        newSetData = setUpdatedBusinessInfo;
        break;
      default:
        return;
    }

    // Setting selectedRetryData to capture the current item, category, and the index if applicable.
    // The oldSetData and newSetData functions are also passed along to facilitate state updates
    // in the relevant components.
    setSelectedRetryData({
      retryData: item, // The data item clicked by the user.
      category, // The category of the data item ('owners', 'personalInfo', or 'businessInfo').
      ownerId: category === "owners" ? id : null, // If the category is 'owners', pass the ownerIndex.
      oldSetData, // Function to update the original state.
      newSetData, // Function to update the modified state.
    });
  };

  // Background:
  // The `handleItemClick` function is designed to handle user interactions with items
  // in the `RetryStack` component. When a user clicks on an item, this function determines
  // the appropriate state to update based on the category of the clicked item.
  // The function sets `selectedRetryData`, which contains the necessary data and update
  // functions to be used in the `UpdateRetryInfo` component.
  //
  // By structuring the state management this way, we ensure that:
  // 1. The relevant state is updated based on the user's selection.
  // 2. The state updates are propagated to the `UpdateRetryInfo` component, which can
  //    then display the correct data and handle updates appropriately.
  //
  // Additionally, a `useEffect` hook is used to reset `retryData` whenever the relevant
  // state changes. This ensures that the `retryData` always reflects the latest state,
  // addressing the issue where `InfoList` does not automatically update when the source
  // state changes. This approach ensures that the UI remains consistent and up-to-date
  // with the latest data.
  // Reset retryData whenever relevant state changes

  useEffect(() => {
    if (selectedRetryData) {
      let updatedRetryData;
      switch (selectedRetryData.category) {
        case "owners":
          updatedRetryData = owners.find(
            (owner) => owner.Id.id === selectedRetryData.ownerId
          );
          break;
        case "personalInfo":
          updatedRetryData = personalInfo;
          break;
        case "businessInfo":
          updatedRetryData = businessInfo;
          break;
        default:
          updatedRetryData = {};
      }
      setSelectedRetryData((prevData) => ({
        ...prevData,
        retryData: updatedRetryData,
      }));
    }
  }, [personalInfo, businessInfo, owners]);

  return {
    selectedRetryData,
    setSelectedRetryData,
    submittedImage,
    handleSubmit,
    personalInfo,
    businessInfo,
    owners,
    handleItemClick,
  };
};

export default useCustomerRetryInfo;
