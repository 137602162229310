import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../../functions/apiFetchers/fullPageLoaders";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../features/alerts/alertSlice";
import submittedImage from "../../../../../../images/done.png";

const useDocumentManager = () => {
  const location = useLocation();
  const { documents } = location.state || {};
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentList, setDocumentList] = useState(documents);
  const fetchFeedback = useFullFetchFeedback();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAllDocumentsUploaded = documentList?.every((doc) => doc.isCompleted);
  // check to make sure the data is passed otherwise send back
  useEffect(() => {
    if (!documents) {
      navigate("/status");
    }
  }, [documents, navigate]);

  useEffect(() => {
    const invalidDocuments = documents?.filter(
      (doc) => doc.errors && doc.errors.length > 0
    );
    if (invalidDocuments?.length > 0) {
      dispatch(
        showAlert({
          type: "error",
          message:
            "Some of your documents were invalid. We need you to update them for verification.",
        })
      );
    }
  }, []);

  const onDocumentUpload = async (event) => {
    try {
      await fetchFeedback({
        endpoint: "merchant/document/upload/",
        data: documentList,
        loaderMessage: "Uploading documents...",
        requestType: "POST",
        onSuccess: (responseData) => {
          navigate("/status");
        },
        onError: (error) => {
          console.error("Upload error:", error);
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return {
    submittedImage,
    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,
    setSelectedDocument,

    selectedDocument,
    setDocumentList
  };

}

export default useDocumentManager