import React from "react";
import { Button, Typography, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DaysDialog from "../atoms/dialogUI";

const DataDurationIndicatorUI = ({
  duration,
  setDialogOpen,
  dialogOpen,
  handleClose,
  handleEnter,
  days,
  setDays,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: "-4%", mb: 2 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 1 }}
          >
            Data For Trailing {duration} Days
          </Typography>
          <Button
            variant="text"
            endIcon={<EditIcon />}
            onClick={() => setDialogOpen(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              margin: "auto",
              size: "small",
            }}
          >
            {`Edit Days Shown`}
          </Button>
        </Grid>
      </Grid>
      <DaysDialog
        open={dialogOpen}
        handleClose={handleClose}
        handleEnter={handleEnter}
        days={days}
        setDays={setDays}
      />
    </>
  );
};

export default DataDurationIndicatorUI;
