import { Box, CircularProgress, Paper, Typography } from "@mui/material";

const StatusPagePanelUI = () => {
  return (
    <Box sx={{ maxWidth: 600, mx: "auto", textAlign: "center", p: 4, mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Verifying Your Account
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We are currently verifying your account information with Dwolla, our
          trusted payments and identity provider. This process is essential to
          ensure the security and integrity of our platform.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "gray" }}>
          Please do not close this page or navigate away while we complete the
          verification. This may take a few moments.
        </Typography>
      </Paper>
    </Box>
  );
};

export default StatusPagePanelUI;
