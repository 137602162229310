
import CustomCard from './../../../../components/atoms/cards/customCard'
import { Typography, } from "@mui/material";
import GeneralButton from "./../../../../components/atoms/buttons/GeneralButton";
import HelpInfoDailogButton from './../../../../components/atoms/dailog/helpInfoDailogButton';

const AddBankCardUI = ({
    activeInfoDailog,
    handleInfoDailog,
    handleAccountClick,
    activeCardInfoDailog
}) => {

    return (
        <>
        <CustomCard>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Bank Account
            </Typography>
            <Typography variant="h4" component="div" sx={{ mb: 2 }}>
            No account on file
            </Typography>
            <Typography variant="body1" sx={{ width: "50%", textAlign: "center" }}>
            You need to add a bank account to start processing payments.
            </Typography>
            <GeneralButton
            label="Add Account"
            onClick={handleAccountClick}
            type="contained"
            size="medium"
            center={true}
            />
            {<HelpInfoDailogButton
                open={activeInfoDailog && activeCardInfoDailog === 'addBank'}
                title={"No account on file."}
                info={
                    "Connect a bank account easily to activate your account and be able to receive payments directly into your bank account."
                }
                handleInfoDailog={() => {handleInfoDailog('addBank')}}
            />}
        </CustomCard>
        </>
    );
    }

export default AddBankCardUI;