// handleNavigation.js
function handleNavigation(data, navigate, setStatus) {
  console.log(data);

  switch (data.status) {
    case "documentPending":
      setStatus("verifyPending");
      navigate("/status/pending");
      break;
    case "certified":
      setStatus("complete");
      navigate("/dashboard");
      break;
    case "retry":
      navigate("/status/retry", {
        state: {
          retry_controller_data: data?.retry_controller_data,
          retry_business_data: data?.retry_business_data,
          owner_retry_data: data?.owner_retry_data,
        },
      });
      break;
    case "document":
      navigate("/status/document", { state: { documents: data?.documents } });
      break;
    case "suspended":
      setStatus("verifySuspended");
      navigate("/status/suspended");
      break;
    default:
      // Handle unknown status or navigate to a default page
      console.warn("Unknown status:", data.status);
      navigate("/default");
      break;
  }
}

export default handleNavigation;
