import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";

const EmailCodePageInputsUI = ({ code, setCode, error }) => {
  return (
    <GeneralInput
      type={"text"}
      label="Enter Code"
      name={"code"}
      value={code}
      onChange={(e) => setCode(e.target.value)}
      error={!!error}
      errorMessageAndhelp={error.otp || ""}
    />
  );
};

export default EmailCodePageInputsUI;
