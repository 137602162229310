import React, { useEffect } from "react";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import StatusPagePanelContainer from "../organisms/statusPagePanelContainer";
import useStatus from "../../functional/page/useStatus";
import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";

const StatusPageUI = () => {
  const { submittedImage, title, description, currentStep } = useStatus();
  return (
    <VerificationPageContainer
     
      title={title}
      description={description}
      currentStep={currentStep}
      button={false}
    >
      <StatusPagePanelContainer />
    </VerificationPageContainer>
  );
};

export default StatusPageUI;
