import React, { useState } from "react";
import PageLayout from "../../../../../components/pageStructures/authPages/master";
import EmailCodePageInputsContainer from "../organisms/emailCodePageInputsContainer";
import useEmailCode from "../../functional/page/useEmailCode";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

const EmailCodePage = () => {
  const { codeImage, handleSubmit, title, description, code, setCode, error } =
    useEmailCode();
  return (
    <SignUpPageContainer
      
      handleSubmit={handleSubmit}
      title={title}
      description={description}
      currentStep={2}
    >
      <EmailCodePageInputsContainer
        code={code}
        setCode={setCode}
        error={error}
      />
    </SignUpPageContainer>
  );
};

export default EmailCodePage;
