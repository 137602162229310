import React, { useState } from "react";
// import submittedImage from "../../../../images/done.png";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import InfoListUI from "../molecules/infoListUI";
import ChangeDataDialogueUI from "../molecules/changeDataDialogueUI";
import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";

const UpdateRetryInfoPageUI = ({
  selectedRetryData,
  navigateBackToController,
  dialogOpen,
  handleItemClick,
  setDialogOpen,
  dialogData,
  submittedImage,
  formatValue,
  handleSubmit,
  formData,
  formatAddress,
  handleInputChange,
}) => {
  return (
    <>
      <VerificationPageContainer
        sideImageUrl={submittedImage}
        title={"Customer Retry Information"}
        description={
          "Recheck your information and make sure to add in your full social and other information."
        }
        onBack={navigateBackToController}
        backArrow={true}
        currentStep={5}
      >
        <InfoListUI
          data={selectedRetryData.retryData}
          onItemClick={handleItemClick}
          formatValue={formatValue}
        />
      </VerificationPageContainer>
      {dialogOpen && (
        <ChangeDataDialogueUI
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          dialogData={dialogData}
          selectedRetryData={selectedRetryData}
          handleSubmit={handleSubmit}
          formData={formData}
          formatAddress={formatAddress}
          handleInputChange={handleInputChange}
        />
      )}
    </>
  );
};

export default UpdateRetryInfoPageUI;
