import usePersonalInfo from "../../functional/page/usePersonalInfo";
import OwnerApplicationUIContainer from "../organisms/ownerApplicationUIContainer";
import PersonalInfoFormUIContainer from "../organisms/personalInfoFormUIContainer";

const PersonalInfoPage = () => {
  const {
    value,
    personalInfo,
    handleInputChange,
    controllerIndex,
    setControllerIndex,
    setOwners,
    setValue,
    owners,
    handleSubmit,
    personalInfoImage,
    PersonalInfoFormHandleSubmit,
    fieldConfig,
    errors,
    handleAdd,
    handleDelete,
    handleOwnerClick,
    onClick,
    inputConfigs,
    owner,
    ownerInfoFormHandleInputChange,
    setOwner,
    ownerInfoFormErrors,
    handleDeleteClick,
    personalInfoFormErrors,
    showForm,
    setShowForm,
  } = usePersonalInfo();
  return (
    <>
      {value === 0 && (
        <PersonalInfoFormUIContainer
          personalInfoImage={personalInfoImage}
          handleSubmit={PersonalInfoFormHandleSubmit}
          fieldConfig={fieldConfig}
          personalInfo={personalInfo}
          handleInputChange={handleInputChange}
          errors={personalInfoFormErrors}
          // controllerIndex={controllerIndex}
          // setControllerIndex={setControllerIndex}
          // setOwners={setOwners}
          // setValue={setValue}
        />
      )}
      {value === 1 && (
        <OwnerApplicationUIContainer
          owners={owners}
          setOwners={setOwners}
          navigateBackToController={() => {
            setValue(0);
          }}
          controllerIndex={controllerIndex}
          handleSubmit={handleSubmit}
          showForm={showForm}
          // activeOwner={activeOwner}
          setShowForm={setShowForm}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleOwnerClick={handleOwnerClick}
          personalInfoImage={personalInfoImage}
          onClick={onClick}
          inputConfigs={inputConfigs}
          owner={owner}
          handleInputChange={ownerInfoFormHandleInputChange}
          setOwner={setOwner}
          errors={ownerInfoFormErrors}
          handleDeleteClick={handleDeleteClick}
        />
      )}
    </>
  );
};

export default PersonalInfoPage;
