import React from "react";
import { CardContent } from "@mui/material";
import StatusUI from "../atoms/statusUI";
import Infopop from './../../../../components/molecules/dialogues/infoNoIcon';

const AccountStatusPanelUI = ({requirements, profileStatus, isActive, handleIconClick, dailogDetails, activeDailog, handleDailogClose}) => {
    return(
        <>
            <CardContent
            sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
            mx: "auto",
            mt: 5,
            }}
        >
            <StatusUI
                title="Account Activation"
                statusName="Activation"
                status={isActive ? true : false}
                handleIconClick={handleIconClick}
                requirements={requirements}
            />
            <StatusUI
                title="Discover App Visibility"
                statusName="Visibility"
                status={isActive ? true : false}
                handleIconClick={handleIconClick}
                requirements={profileStatus}
            />
        </CardContent>
        <Infopop
            open={activeDailog}
            title={dailogDetails ? dailogDetails.title : ''}
            info={dailogDetails ? dailogDetails.info : ''}
            onClose={handleDailogClose}
        />
    </>
    );
}

export default AccountStatusPanelUI;