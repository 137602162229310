import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import VerificationPage from './pages/signUp/verification/status/combined/page/statusPage.js';
import AccountInfoPage from './pages/signUp/account/combined/page/accountInfoPage.js';
import EmailCodePage from './pages/signUp/emailCode/combined/page/emailCodePage.js'
import BankPage from "./pages/bank/combined/page/bankPage.js"
import ProfilePage from './pages/profile/combined/page/profilePage.js'
import RegisterPage from "./pages/register/combined/page/registerPage.js"
import SettingsPage from "./pages/settings/combined/page/settingPage.js";
import { Provider } from "react-redux";
import store from "./app/store";
import ReduxComponents from "./reduxComponents/main";
import DashboardPage from "./pages/dashboard/combined/page/dashboardPage.js";
import EmailPage from './pages/signUp/email/combined/page/emailPage.js';
import PersonalInfoPage from "./pages/signUp/pesonalAndOwner/combined/page/personalInfoPage.js";
import BusinessInfoPage from './pages/signUp/business/combined/page/businessInfoPage.js';
import DocumentStatusList from "./pages/signUp/verification/documents/combined/page/documentPage.js";
import CustomerRetryInfo from "./pages/signUp/verification/retry/combined/page/customerRetryInfoPage.js";
import MainRouteGuard from "./functions/auth/routeGuards/mainRouteGuard.js";
import MidAuthRouteGuard from "./functions/auth/routeGuards/midAuth.js";
import ProfilePkCheckRouteGuard from "./functions/auth/routeGuards/profile_pkSignUp.js";
import RetryDataCheckRouteGuard from "./functions/auth/routeGuards/retryVerify.js";
import DocumentCheckRouteGuard from "./functions/auth/routeGuards/documentVerify.js";
import TransactionsPage from "./pages/transactions/combined/page/main.js";
import SignInPage from "./pages/signInUser/combined/page/AccountInfoPage.js";
import CashbackPage from "./pages/cashback/combined/page/cashbackPage.js";
import PendingPage from "./pages/signUp/verification/pending/combined/page/pendingPage.js"
import SuspendedPage from "./pages/signUp/verification/suspended/combined/page/suspendedPage.js";
import SetPageTitle from "./pageTitle.js";
import NotFoundCombined from "./pages/notFound/combined/page/main.js";

import DevelopmentPageUI from "./development/page/main.js";
import DevelopmentComponentUI from "./development/component/main.js";
import ThemeCustomization from './themes/index.js';
import RTLLayout from './ui-component/RTLLayout';
import { JWTProvider } from "./contexts/JWTContext.js";
import { IntlProvider } from "react-intl";
import { Locale } from "intl";

const App = () => {
  return (
    <IntlProvider locale={Locale} >
    <JWTProvider>
    <Provider store={store}>
      <ThemeCustomization>
      <Router>
      <SetPageTitle />
        <ReduxComponents />
        <Routes>
          <Route
            path="/bank"
            element={
              <MainRouteGuard>
                <BankPage />
              </MainRouteGuard>
            }
          />
           <Route
            path="/develpment/component"
            element={
            
                <DevelopmentComponentUI />
          
            }
          />
        
        
          <Route path="/development/page" element={<DevelopmentPageUI />} />
     
          <Route
            path="/cashback"
            element={
              <MainRouteGuard>
                <CashbackPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/dashboard"
            element={
              <MainRouteGuard>
                <DashboardPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/profile"
            element={
              <MainRouteGuard>
                <ProfilePage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/pos/register"
            element={
              <MainRouteGuard>
                <RegisterPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/settings"
            element={
              <MainRouteGuard>
                <SettingsPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/transactions"
            element={
              <MainRouteGuard>
                <TransactionsPage />
              </MainRouteGuard>
            }
          />
  <Route
        path="/personal-info"
        element={
          <MidAuthRouteGuard requiredStatus="personalInfo">
            <PersonalInfoPage />
          </MidAuthRouteGuard>
        }
      />
      <Route
        path="/business-info"
        element={
          <MidAuthRouteGuard requiredStatus="businessInfo">
            <BusinessInfoPage />
          </MidAuthRouteGuard>
        }
      />
      <Route
        path="/status"
        element={
          <MidAuthRouteGuard requiredStatus="verify">
            <VerificationPage />
          </MidAuthRouteGuard>
        }
      />
<Route
        path="/code-verification"
        element={
          <ProfilePkCheckRouteGuard>
            <EmailCodePage />
          </ProfilePkCheckRouteGuard>
        }
      />
      <Route
        path="/account-info"
        element={
          <ProfilePkCheckRouteGuard>
            <AccountInfoPage />
          </ProfilePkCheckRouteGuard>
        }
      />
      <Route
        path="/status/retry"
        element={
          <RetryDataCheckRouteGuard>
            <CustomerRetryInfo />
          </RetryDataCheckRouteGuard>
        }
      />
      <Route
        path="/status/document"
        element={
          <DocumentCheckRouteGuard>
            <DocumentStatusList />
          </DocumentCheckRouteGuard>
        }
      />
      <Route
        path="/status/pending"
        element={
        <DocumentCheckRouteGuard>
          <PendingPage />
        </DocumentCheckRouteGuard>
        }
      />
      <Route
        path="/status/suspended"
        element={
        <DocumentCheckRouteGuard>
          <SuspendedPage />
        </DocumentCheckRouteGuard>
      }
    />
          <Route
            path="/sign-in"
            element={
                <SignInPage />
            }
          />
          <Route
            path="/email"
            element={
                <EmailPage />
            }
          />
            <Route
            path="*"
            element={
                <NotFoundCombined />
            }
          />
        </Routes>
      </Router>
      </ThemeCustomization>
    </Provider>
    </JWTProvider>
    </IntlProvider>
  );
};
export default App;