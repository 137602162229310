import { useState } from "react";
import useFullFetchFeedback from "../../../../../functions/apiFetchers/fullPageLoaders";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validateField } from "../../../../../functions/validators/master";
import { setStatus } from "../../../../../features/user/userSlice";
import usePersonalInfoForm from "../organisms/usePersonalInfoForm";
import useOwnerApplication from "../organisms/useOwnerApplication";
import useOwnerInfoFormInputs from "../molecules/useOwnerInfoFormInputs";
import useOwnerManager from "../molecules/useOwnerManager";

const usePersonalInfo = () => {
  const [value, setValue] = useState(0); // Tab value
  const [personalInfo, setPersonalInfo] = useState({
    first_name: "",
    last_name: "",
    title: "",
    date_of_birth: "",
    address: { address: "", city: "", state: "", zip_code: "" },
    ssn: "",
    isOwner: false,
  });
  const [errors, setError] = useState("");
  const [controllerIndex, setControllerIndex] = useState(null);
  const [owners, setOwners] = useState([]);
  const fetchFeedback = useFullFetchFeedback();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setPersonalInfo((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    const errors = validateField("list", owners);
    if (errors) {
      return;
    } else {
      await fetchFeedback({
        endpoint: "merchant/personal_info/",
        data: { owners, personal_info: personalInfo },
        loaderMessage: "Submitting personal details...",
        requestType: "POST",
        onSuccess: (responseData) => {
          dispatch(setStatus("businessInfo"));
          navigate("/business-info");
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const {
    personalInfoImage,
    handleSubmit: PersonalInfoFormHandleSubmit,
    fieldConfig,
    errors: personalInfoFormErrors,
  } = usePersonalInfoForm({
    personalInfo,
    controllerIndex,
    setOwners,
    setControllerIndex,
    setValue,
  });

  const {
    handleAdd,
    handleDelete,
    handleOwnerClick,
    activeOwner,
    showForm,
    setShowForm,
  } = useOwnerApplication({ controllerIndex, owners, setOwners });

  const {
    onClick,
    inputConfigs,
    owner,
    handleInputChange: ownerInfoFormHandleInputChange,
    setOwner,
    errors: ownerInfoFormErrors,
  } = useOwnerInfoFormInputs({
    activeOwner,
    setOwners,
    handleSubmit: () => {
      setShowForm(false);
    },
  });

  const { handleDeleteClick } = useOwnerManager({ onDelete: handleDelete });

  return {
    value,
    personalInfo,
    handleInputChange,
    controllerIndex,
    setControllerIndex,
    setOwners,
    setValue,
    owners,
    handleSubmit,
    errors,

    personalInfoImage,
    PersonalInfoFormHandleSubmit,
    fieldConfig,
    personalInfoFormErrors,

    handleAdd,
    handleDelete,
    handleOwnerClick,

    onClick,
    inputConfigs,
    owner,
    ownerInfoFormHandleInputChange,
    setOwner,
    ownerInfoFormErrors,
    handleDeleteClick,
    showForm,
    setShowForm,
  };
};

export default usePersonalInfo;
