import BusinessInfoPageInputsUI from "../../ui/organisms/businessInfoPageInputsUI";

const BusinessInfoPageInputsContainer = ({
  fieldConfig,
  businessInfo,
  handleInputChange,
  setBusinessInfo,
  errors,
}) => {
  return (
    <BusinessInfoPageInputsUI
      fieldConfig={fieldConfig}
      businessInfo={businessInfo}
      handleInputChange={handleInputChange}
      setBusinessInfo={setBusinessInfo}
      errors={errors}
    />
  );
};

export default BusinessInfoPageInputsContainer;
