import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../functions/apiFetchers/fullPageLoaders";

const useEmail = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (event) => {
    const errorValidation = [{ field: "email", type: "email", required: true }];

    await fetchFeedback({
      endpoint: "merchant/email/",
      requestType: "POST",
      auth: false,
      data: { email },
      setError: setError,
      validationRules: errorValidation,
      loaderMessage: "Verifying Email...",

      onSuccess: (responseData) => {
        navigate("/code-verification", {
          state: { profile_pk: responseData.profile_pk },
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };
  const title = "Enter Email";

  const description =
    "Your email ensures secure transactions and keeps you informed about account activities and updates.";
  const currentStep = 1;


  return {
  
    
    handleSubmit,
   
    email,
    setEmail,
    error,
    
 
    title,
    description,
    currentStep,
  };
};

export default useEmail;
