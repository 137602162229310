import { useSelector } from "react-redux";
import { selectDashboardData } from "../../../../features/dashboard/dashboardSelectors";

const useTabs = () => {
  const { cashbackRedeemed, cashbackEarned, totalUsers, transactions } =
    useSelector(selectDashboardData);

  // Define card information in an array to map over later
  const infoBoxes = [
    {
      title: "Transactions",
      value: transactions,
      info: "This number shows the total transactions processed on Clavaa this month, highlighting our platform's active use and reliability. Every transaction reflects our commitment to providing seamless and secure payment solutions for our merchants and their customers.",
      isGreen: true,
    },
    {
      title: "Cashback Redeemed",
      value: cashbackRedeemed,
      info: "Here, you'll see the total cashback your customers have redeemed. It's not just savings for them but also a testament to the loyalty and repeat business we help bring to your store. It's your way of always being top of mind by your customers.",
    },
    {
      title: "Cashback Earned",
      value: cashbackEarned,
      info: "This figure represents the total cashback earned by users so far, thanks to your amazing offers and our platform's promotions. It's a win-win: your customers save money, and you get more engagement and sales.",
    },
    {
      title: "Total Users",
      value: totalUsers,
      info: "This figure shows the number of customers who have used Clavaa in your store, highlighting the growing reach and effectiveness of our platform in enhancing your business. Each transaction represents an opportunity for increased customer engagement and loyalty, driving growth and loyalty for your business.",
    },
  ];

  return {
    infoBoxes,
  };
};

export default useTabs;
