import React, { useEffect, useState } from "react";

import useBusinessInfo from "../../functional/page/useBusinessInfo";
import BusinessInfoPageInputsContainer from "../organisms/businessInfoPageInputsContainer";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

const BusinessInfoPageUI = () => {
  const {
   
    handleSubmit,
    fieldConfig,
    businessInfo,
    handleInputChange,
    setBusinessInfo,
    errors,
    title,
    description,
    currentStep,
  } = useBusinessInfo();
  return (
    <SignUpPageContainer
     
      handleSubmit={handleSubmit}
      title={title}
      description={description}
      currentStep={currentStep}
    >
      <BusinessInfoPageInputsContainer
        fieldConfig={fieldConfig}
        businessInfo={businessInfo}
        handleInputChange={handleInputChange}
        setBusinessInfo={setBusinessInfo}
        errors={errors}
      />
    </SignUpPageContainer>
  );
};

export default BusinessInfoPageUI;
