import personalInfoImage from "../../../../../images/personal.png";

const useOwnerManager = ({ onDelete }) => {
  // Adjust the handleDeleteClick to return another function
  // This curried function avoids creating an additional anonymous function on each render
  const handleDeleteClick = (ownerId) => (e) => {
    e.stopPropagation();
    onDelete(ownerId);
  };

  return {
    handleDeleteClick,
    personalInfoImage,
  };
};

export default useOwnerManager;
