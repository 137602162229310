import { Box } from "@mui/material";
import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";

const AccountInfoPageInputsUI = ({
  accountInfo,
  handleInputChange,
  error,
  setAccountInfo,
}) => {
  return (
    <Box sx={{ display: "grid", gap: 2 }}>
      <GeneralInput
        type={"text"}
        label="Username"
        name={"username"}
        value={accountInfo.username}
        onChange={(e) => handleInputChange(e, setAccountInfo)}
        error={!!error.username}
        errorMessageAndhelp={error.username || ""}
      />
      <GeneralInput
        type={"password"}
        label="Password"
        name={"password"}
        value={accountInfo.password}
        onChange={(e) => handleInputChange(e, setAccountInfo)}
        error={!!error.password}
        errorMessageAndhelp={error.password || ""}
      />
    </Box>
  );
};

export default AccountInfoPageInputsUI;
