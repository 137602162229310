import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../functions/apiFetchers/fullPageLoaders";

const useEmailCodeInputs = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const profile_pk = location.state?.profile_pk;
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (event) => {
    const errorValidation = [{ field: "otp", type: "number", required: true }];

    await fetchFeedback({
      endpoint: "email/confirm/",
      
      requestType: "POST",
      auth: false,
      data: { otp: code, profile_pk: profile_pk },
      setError: setError,
      validationRules: errorValidation,
      loaderMessage: "Verifying Email...",
      // On success, navigate to the code-verification page
      onSuccess: (responseData) => {
        navigate("/account-info", {
          state: { profile_pk: profile_pk, email: responseData.email },
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return {
    code,
    setCode,
    error,
    handleSubmit,
  };
};

export default useEmailCodeInputs;
