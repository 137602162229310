// POS Management Menu
import { FormattedMessage } from 'react-intl';
import StoreIcon from '@mui/icons-material/Store';  // Icon for POS

const icons = {
    StoreIcon
};

const posManagementMenu = {
    id: 'pos-management',
    title: <FormattedMessage id="pos-management" />,
    type: 'group',

    children: [
        {
            id: 'register',
            title: <FormattedMessage id="register" />,
            type: 'item',
            url: '/pos/register',
            icon: icons.StoreIcon,
            breadcrumbs: false
        }
    ]
};

export default posManagementMenu;
