import React from 'react';
import SettingsPanelUI from '../../ui/organisms/settingsPanelUI'; 
import SettingsLogic from "../../functional/organisms/settingsLogic";

const SettingsComponent = () => {
  const { 
    tabValue,
    requirements,
    profileStatus,
    isActive,
    dailogDetails,
    activeDailog,
    handleIconClick,
    handleChange,
    handleSignOut,
    handleDailogClose
   } = SettingsLogic();

  return (
     <SettingsPanelUI
        tabValue={tabValue}
        requirements={requirements}
        profileStatus={profileStatus}
        isActive={isActive}
        dailogDetails={dailogDetails}
        activeDailog={activeDailog}
        handleIconClick={handleIconClick}
        handleChange={handleChange}
        handleSignOut={handleSignOut}
        handleDailogClose={handleDailogClose}
     />
  );
};

export default SettingsComponent;
