import { useState } from "react";

const useDialog = ({ open, setOpen, onSubmit }) => {
  const [days, setDays] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnter = () => {
    onSubmit(parseInt(days, 10));
    setOpen(false);
  };

  return {
    days,
    setDays,
    handleClose,
    handleEnter,
    open,
  };
};

export default useDialog;
