import React from "react";
import { Box } from "@mui/material";

import DaysPanel from "../organisms/daysPanelUI";
import TransactionsTablePanel from "../organisms/transactionsTablePanelUI";

const DashboardPageUI = ({
  duration,
  setDialogOpen,
  dialogOpen,
  handleSubmitDuration,
  open,
  handleClose,
  handleEnter,
  days,
  setDays,
  infoBoxes,
  displayedTransactions,
  activeInfoDailog,
  handleInfoDailog,
  info,
  activeIndex,
  loading,
}) => {
  return (
    <Box >
      <DaysPanel
        duration={duration}
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        handleEnter={handleEnter}
        days={days}
        setDays={setDays}
        infoBoxes={infoBoxes}
        activeInfoDailog={activeInfoDailog}
        handleInfoDailog={handleInfoDailog}
        info={info}
        activeIndex={activeIndex}
      />
      <TransactionsTablePanel
        displayedTransactions={displayedTransactions}
        loading={loading}
      />
    </Box>
  );
};

export default DashboardPageUI;
