import React from "react";
import { Typography } from "@mui/material";
import GeneralButton from "./../../../../components/atoms/buttons/GeneralButton";
import CustomCard from "./../../../../components/atoms/cards/customCard";
import HelpInfoDailogButton from './../../../../components/atoms/dailog/helpInfoDailogButton';

const BankCardUI = ({
  activeInfoDailog,
  activeCardInfoDailog,
  bankData,
  handleAccountClick,
  handleInfoDailog
}) => {
    // Check if logo is Base64 by checking for the 'data:image' prefix
    const isBase64 = bankData.logo && bankData.logo.startsWith('data:image');
    
    // If it's Base64, prepend 'data:image/png;base64,' if not already present, otherwise use URL as is
    const logoSrc = isBase64
        ? bankData.logo // Base64 string with 'data:image' prefix already
        : bankData.logo.startsWith('http') // If it's an HTTP(S) URL, use it directly
        ? bankData.logo // It's a URL
        : `data:image/png;base64,${bankData.logo}`; // Assume it's a Base64-encoded string without the prefix

    return (
        <>
        <CustomCard>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            Bank Balance
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "500" }}>
            ********{bankData.accountNumber}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Account details
          </Typography>
          <img
            src={logoSrc}
            alt="Bank Logo"
            style={{ width: 50, borderRadius: "50%" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography>
              <div>{bankData.accountName}</div>
              <div>Balance {bankData.balance}</div>
            </Typography>
          </div>
          <GeneralButton
            label="Switch Account"
            onClick={handleAccountClick}
            type="outlined"
          />
          <HelpInfoDailogButton
            open={activeInfoDailog && activeCardInfoDailog === 'bankCard'}
            title={"Account Information"}
            info={
              "This section provides detailed information about your bank account, such as balance and transaction history. Ensure your account is always connected for up-to-date information."
            }
            handleInfoDailog={() => {handleInfoDailog('bankCard')}}
            />
        </CustomCard>
      </>
    )
}

export default BankCardUI;
