import React from "react";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import DocumentStackUI from "../molecules/documentStackUI";
import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";

const DocumentManagerUI = ({
  submittedImage,
  onDocumentUpload,
  isAllDocumentsUploaded,
  documentList,
  setSelectedDocument,
}) => {
  return (
    <VerificationPageContainer
      
      title={"Required Documents"}
      description={
        "We just need a couple documents to verify some of your information."
      }
      currentStep={4}
      handleSubmit={onDocumentUpload}
      disabled={!isAllDocumentsUploaded}
    >
      <DocumentStackUI
        data={documentList}
        onItemClick={(document, index) =>
          setSelectedDocument({ document, index })
        }
      />
    </VerificationPageContainer>
  );
};

export default DocumentManagerUI;
