import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DynamicForm from "../../../../../components/atoms/inputs/dynamic";
const OwnerInfoFormUIInputs = ({
  inputConfigs,
  owner,
  handleInputChange,
  setOwner,
  errors,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "100%",
      }}
    >
      <DynamicForm
        fieldConfig={inputConfigs}
        formData={owner}
        handleInputChange={(e) => handleInputChange(e, setOwner)}
        errors={errors}
      />
    </Box>
  );
};

export default OwnerInfoFormUIInputs;
