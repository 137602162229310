import DocumentUploadFieldUI from "../../ui/organisms/documentUploadFieldUI";

const DocumentUploadFieldUIContainer = ({
  documentDetails,
  setSelectedDocument,
  handleDocumentUpload,
  type,
  setType,
  setFile,
  submittedImage,
  documentTypeFieldOnClick,
  documentUploadFieldOnClick,
  handleFileChange,
  documentName,
}) => {
  return (
    <DocumentUploadFieldUI
      documentDetails={documentDetails}
      setSelectedDocument={setSelectedDocument}
      handleDocumentUpload={handleDocumentUpload}
      type={type}
      setType={setType}
      setFile={setFile}
      submittedImage={submittedImage}
      documentTypeFieldOnClick={documentTypeFieldOnClick}
      documentUploadFieldOnClick={documentUploadFieldOnClick}
      handleFileChange={handleFileChange}
      documentName={documentName}
    />
  );
};

export default DocumentUploadFieldUIContainer;
