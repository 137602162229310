import OwnerApplicationUI from "../../ui/organisms/ownerApplicationUI";

const OwnerApplicationUIContainer = ({
  owners,
  setOwners,
  navigateBackToController,
  controllerIndex,
  handleSubmit,
  showForm,
  activeOwner,
  setShowForm,
  handleAdd,
  handleDelete,
  handleOwnerClick,

  personalInfoImage,
  onClick,
  inputConfigs,
  owner,
  handleInputChange,
  setOwner,
  errors,
  handleDeleteClick,
}) => {
  return (
    <OwnerApplicationUI
      owners={owners}
      setOwners={setOwners}
      navigateBackToController={navigateBackToController}
      controllerIndex={controllerIndex}
      handleSubmit={handleSubmit}
      showForm={showForm}
      activeOwner={activeOwner}
      setShowForm={setShowForm}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleOwnerClick={handleOwnerClick}
      personalInfoImage={personalInfoImage}
      onClick={onClick}
      inputConfigs={inputConfigs}
      owner={owner}
      handleInputChange={handleInputChange}
      setOwner={setOwner}
      errors={errors}
      handleDeleteClick={handleDeleteClick}
    />
  );
};

export default OwnerApplicationUIContainer;
