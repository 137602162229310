import { Box } from "@mui/material";
import DynamicForm from "../../../../../components/atoms/inputs/dynamic";

const BusinessInfoPageInputsUI = ({
  fieldConfig,
  businessInfo,
  handleInputChange,
  setBusinessInfo,
  errors,
}) => {
  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      <DynamicForm
        fieldConfig={fieldConfig}
        formData={businessInfo}
        handleInputChange={(e) => handleInputChange(e, setBusinessInfo)}
        errors={errors}
      />
    </Box>
  );
};

export default BusinessInfoPageInputsUI;
