// Cashback Menu
import { FormattedMessage } from 'react-intl';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const icons = {
    MonetizationOnIcon
};

const cashbackMenu = {
    id: 'cashback',
    title: <FormattedMessage id="cashback" />,
    type: 'group',

    children: [
        {
            id: 'cashback-item',
            title: <FormattedMessage id="cashback" />,
            type: 'item',
            url: '/cashback',
            icon: icons.MonetizationOnIcon,
            breadcrumbs: false
        }
    ]
};

export default cashbackMenu;
