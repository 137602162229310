import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import { setInitialDashboardData } from '../../../features/initialData/initialDataSlice';
import { selectHasFetched } from '../../../features/initialData/initialDataSelector';
import { selectUserStatus } from '../../../features/user/userSelectors';

import { masterRequest } from '../../apiStructures/masterRequest';
import InitialLoadingPage from '../../../pages/loading/initialDataLoader';

const MainRouteGuard = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const userStatus = useSelector(selectUserStatus);
  const fetched = useSelector(selectHasFetched);

  useEffect(() => {
   
    const initializeUser = async () => {
      try {
        if (!fetched) {
          setLoading(true);
        
          const token = localStorage.getItem("token");
          console.log(token)
          if (token) {
            const data = await masterRequest(`merchant/dashboard/data/`, "GET", null, token);
            console.log(data)
            dispatch(setInitialDashboardData(data))
          }
        }
      } catch (error) {
        console.error('Error initializing user:', error);

      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, [dispatch, fetched]);

  if (loading) {
    return <InitialLoadingPage/>; // Or a loading spinner
  }

  // Redirect based on user status

  switch (userStatus) {

    case 'notAuthenticated':
      return <Navigate to="/sign-in" replace state={{ from: location }} />;
    case 'personalInfo':
      return <Navigate to="/personal-info" replace state={{ from: location }} />;
    case 'businessInfo':
      return <Navigate to="/business-info" replace state={{ from: location }} />;
    case 'verify':
      return <Navigate to="/status" replace state={{ from: location }} />;
    case 'verifyPending':
        return <Navigate to="/status/pending" replace state={{ from: location }} />;
    case 'verifySuspended':
            return <Navigate to="/status/suspended" replace state={{ from: location }} />;
    case 'complete':
      return <>{children}</>;
    default:
      console.log(("unknown status - " + userStatus))
      return <Navigate to="/sign-in" replace state={{ from: location }} />;
  }
};

export default MainRouteGuard;
