import React, { useState } from "react";

import AccountInfoPageInputsContainer from "../organisms/accountInfoPageInputsContainer";
import useAccountInfo from "../../functional/page/useAccountInfo";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

const AccountInfoPage = () => {
  const {

    handleSubmit,
    title,
    description,
    currentStep,
 
    accountInfo,
    handleInputChange,
    error,
    setAccountInfo,
  } = useAccountInfo();
  return (
    <SignUpPageContainer
    
      handleSubmit={handleSubmit}
      title={title}
      description={description}
      currentStep={currentStep}
   
    >
      <AccountInfoPageInputsContainer
        accountInfo={accountInfo}
        handleInputChange={handleInputChange}
        error={error}
        setAccountInfo={setAccountInfo}
      />
    </SignUpPageContainer>
  );
};

export default AccountInfoPage;
