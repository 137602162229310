import React, { useState, useEffect } from "react";

import OwnerInfoFormUIInputs from "../molecules/ownerInfoFormUIInputs";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";
const OwnerInfoFormUI = ({
  personalInfoImage,
  onClick,
  navigateBackToOwnerManager,
  inputConfigs,
  owner,
  handleInputChange,
  setOwner,
  errors,
}) => {
  return (
    <SignUpPageContainer
      
      handleSubmit={onClick}
      title={"Owner Details"}
      description={"Please fill in the businesses controllers details."}
      currentStep={3}
      onBack={navigateBackToOwnerManager}
      backArrow={true}
    >
      <OwnerInfoFormUIInputs
        inputConfigs={inputConfigs}
        owner={owner}
        handleInputChange={handleInputChange}
        setOwner={setOwner}
        errors={errors}
      />
    </SignUpPageContainer>
  );
};

export default OwnerInfoFormUI;
