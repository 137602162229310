import React from "react";
import DashboardPageUI from "../../ui//page/dashboardPageUI";
import DashboardLogic from "../../functional/page/dashboardLogic";

const DashboardPageContainer = () => {
  const {
    duration,
    setDialogOpen,
    dialogOpen,
    handleSubmitDuration,
    open,
    handleClose,
    handleEnter,
    days,
    setDays,
    infoBoxes,
    displayedTransactions,
    activeInfoDailog,
    handleInfoDailog,
    info,
    activeIndex,
    loading,
  } = DashboardLogic();
  return (
    <DashboardPageUI
      duration={duration}
      setDialogOpen={setDialogOpen}
      dialogOpen={dialogOpen}
      handleSubmitDuration={handleSubmitDuration}
      open={open}
      handleClose={handleClose}
      handleEnter={handleEnter}
      days={days}
      setDays={setDays}
      infoBoxes={infoBoxes}
      displayedTransactions={displayedTransactions}
      activeInfoDailog={activeInfoDailog}
      handleInfoDailog={handleInfoDailog}
      info={info}
      activeIndex={activeIndex}
      loading={loading}
    />
  );
};

export default DashboardPageContainer;
