import React from "react";
import CustomTable from "../../../../components/molecules/tables/transactions";

const TransactionsTableUI = ({ displayedTransactions, loading }) => {
  return (
    <CustomTable
      title="Most Recent Transactions"
      columns={[
        { title: "Customer", key: "name" },
        { title: "Invoice ID", key: "id" },
        { title: "Date", key: "date" },
        { title: "Amount", key: "amount" },
        { title: "Cashback Earned", key: "cashbackEarned" },
        { title: "Cashback Redeemed", key: "cashbackAmount" },
        { title: "Status", key: "processing_status" },
        { title: "Register", key: "register" },
      ]}
      data={displayedTransactions}
      loading={loading}
    />
  );
};

export default TransactionsTableUI;
