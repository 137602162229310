

import { Box } from "@mui/system";
import CashbackCardUI from "../molecules/cashbackCardUI";
import CashbackUpdateModalUI from "../molecules/cashbackUpdateModalUI";
import CashbackHistoryModalUI from "../molecules/cashbackHistoryModalUI";

const CashBackPageUI = ({
  currentCashback,
  cashbackHistory,
  isUpdateModalOpen,
  isHistoryModalOpen,
  handleOpenUpdateModal,
  handleCloseUpdateModal,
  handleOpenHistoryModal,
  handleCloseHistoryModal,
  handleCashbackChange,
  cashbackValue,
  handleUpdateCashbackSubmit,
  handleClose,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <CashbackCardUI
        onViewHistory={handleOpenHistoryModal}
        onUpdateCashback={handleOpenUpdateModal}
        currentCashback={currentCashback}
      />
      <CashbackUpdateModalUI
        handleCashbackChange={handleCashbackChange}
        cashbackValue={cashbackValue}
        handleUpdateCashbackSubmit={handleUpdateCashbackSubmit}
        handleClose={handleClose}
        open={isUpdateModalOpen}
        onClose={handleCloseUpdateModal}
        currentCashback={currentCashback}
      />
      <CashbackHistoryModalUI
        open={isHistoryModalOpen}
        onClose={handleCloseHistoryModal}
        cashbackHistory={cashbackHistory}
      />
    </Box>
  );
};

export default CashBackPageUI;