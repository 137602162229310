import React from "react";


import useEmail from "../../functional/page/useEmail";
import AuthPage from "../../../../../development/page/implemement/main";
import MiddleCardContent from "../../../components/organsims/input-boxes";
import EmailPageInputsUI from "../../ui/organisms/emailPageInputsUI";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

const EmailPage = () => {
  const {
    handleSubmit,
    title,
    description,
    currentStep,
    
    email,
    setEmail,
    error,
 
  } = useEmail();

  return (
    <SignUpPageContainer   
    currentStep={currentStep}
    handleSubmit={handleSubmit}
    title={title}
    description={description}>
     
      
       
   
        <EmailPageInputsUI
      email={email}
      setEmail={setEmail}
      error={error}
     
    
    />
    </SignUpPageContainer>
  );
};

export default EmailPage;
