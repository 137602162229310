import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { masterRequest } from "../../../../../../functions/apiStructures/masterRequest";
import { showAlert } from "../../../../../../features/alerts/alertSlice";
import handleNavigation from "../helpers/navigate";
import { setStatus } from "../../../../../../features/user/userSlice";
import submittedImage from "../../../../../../images/done.png";

const useStatus = () => {
  const title = "Account Verification";
  const description =
    "Ensuring the safety and security of our platform is our top priority.";
  const currentStep = 5;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchVerificationStatus() {
      try {
        const token = localStorage.getItem("token");
        const data = await masterRequest(
          `merchant/verification-status/`,
          "GET",
          null,
          token
        );
        if (data?.error) {
          dispatch(
            showAlert({
              type: "warning",
              message:
                "We are encountering an error. Try again later or contact customer service.",
            })
          );
        } else {
          handleNavigation(data, navigate, (status) =>
            dispatch(setStatus(status))
          );
        }
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    }

    fetchVerificationStatus();
  }, []);

  return {
    submittedImage,
    title,
    description,
    currentStep,
  };
};

export default useStatus;
