import CustomerRetryInfoPageUI from "../../ui/organisms/customerRetryInfoPageUI";

const CustomerRetryInfoPageUIContainer = ({
  submittedImage,
  handleSubmit,
  personalInfo,
  businessInfo,
  owners,
  handleItemClick,
}) => {
  return (
    <CustomerRetryInfoPageUI
      submittedImage={submittedImage}
      handleSubmit={handleSubmit}
      personalInfo={personalInfo}
      businessInfo={businessInfo}
      owners={owners}
      handleItemClick={handleItemClick}
    />
  );
};

export default CustomerRetryInfoPageUIContainer;
