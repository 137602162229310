import React from "react";
import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import SettingsComponent from '../organisms/settingsComponent'

const SettingsPage = () => {
  return (
     <MainLayout centerContent={false}>
       <SettingsComponent/>      
     </MainLayout>
  );
};

export default SettingsPage;
