import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from '@mui/material/styles';
import headerBackground from '../../../../../images/bg-header.jpg';
import { styled } from '@mui/material/styles';

import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    IconButton,
    Typography,
    useMediaQuery
} from '@mui/material';
import mailImg from '../../../../../images/widget-mail.svg';
import AnimatedMessages from '../molecule/message';
const HeaderWrapper = styled('div')(({ theme }) => ({
    backgroundImage: `url(${headerBackground})`,
    backgroundSize: '100% 600px',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    paddingTop: 30,
    [theme.breakpoints.down('md')]: {
        paddingTop: 0
    }
}));


const AuthPage = ({

  title,

  children, // accepting children here
}) => {
    const isBelow700px = useMediaQuery("(max-width:700px)");
    const theme = useTheme();

    return (
        <HeaderWrapper>
        <Container>
            <Grid container justifyContent="center">
                <Grid item sm={10} md={7} sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 12.5, xs: 2.5 } }}>

                    <Grid container>
                    <Grid item xs={12} >
                            <Typography
                                variant="h1"
                                color="white"
                                component="div"
                                sx={{
                                    fontSize: '3.5rem',
                                    fontWeight: 900,
                                    lineHeight: 1.4,
                                    [theme.breakpoints.down('md')]: { fontSize: '1.8125rem', marginTop: '80px',  }
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimatedMessages />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ position: 'relative', display: { xs: 'none', lg: 'block' } }}>
      <img
        src={mailImg}
        alt="Berry"
        style={{
          marginBottom: -0.625,
          position: 'absolute',
          bottom: -140,
          right: '-20px',
          width: 400,
          maxWidth: '100%',
          animation: 'wings 5s ease-in-out infinite',
        }}
      />
      {/* Keyframes for the animation */}
      <style>
        {`
          @keyframes wings {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
    </Grid>

                <Grid item xs={12} sm={6} sx={{ mb: -37.5 }}>
                    <Card sx={{ mb: 6.25 }} elevation={4}>
                        <CardContent sx={{ p: isBelow700px ? 1 : 4 }}>
                          

                            {/* Render the children inside the card */}
                            {children}

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        </HeaderWrapper>
    );
};

export default AuthPage;
