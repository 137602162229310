import useCashbackUpdateModalUI from "../molecules/useCashbackUpdateModalUI";
import { useSelector } from "react-redux";
import {
  selectCashbackHistory,
  selectCurrentCashback,
} from "../../../../features/cashback/cashbackSelectors";
import { useState } from "react";

const CashBackLogic = () => {

        const currentCashback = useSelector(selectCurrentCashback);
        const cashbackHistory = useSelector(selectCashbackHistory);
        const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
        const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);

        const handleOpenUpdateModal = () => setUpdateModalOpen(true);
        const handleCloseUpdateModal = () => setUpdateModalOpen(false);

        const handleOpenHistoryModal = () => setHistoryModalOpen(true);
        const handleCloseHistoryModal = () => setHistoryModalOpen(false);

      const {
        handleCashbackChange,
        handleUpdateCashbackSubmit,
        handleClose,
        cashbackValue,
      } = useCashbackUpdateModalUI({ onClose:handleCloseUpdateModal });

      return {
        currentCashback,
        cashbackHistory,
        isUpdateModalOpen,
        isHistoryModalOpen,
        handleOpenUpdateModal,
        handleCloseUpdateModal,
        handleOpenHistoryModal,
        handleCloseHistoryModal,
        handleCashbackChange,
        handleUpdateCashbackSubmit,
        handleClose,
        cashbackValue,
      }
}

export default CashBackLogic