import React from "react";
import { Stack } from "@mui/material";
import PageLayout from "../../../../../../components/pageStructures/authPages/master";
import DocumentTypeFieldUI from "../molecules/documentTypeFieldUI";
import UploadFieldUI from "../molecules/uploadFieldUI";
import VerificationPageContainer from "../../../../../../components/pageStructures/authPages/combined/pages/verificationPageContainer";

const DocumentUploadFieldUI = ({
  documentDetails,
  setSelectedDocument,
  handleDocumentUpload,
  type,
  setType,
  setFile,
  submittedImage,
  documentTypeFieldOnClick,
  documentUploadFieldOnClick,
  handleFileChange,
  documentName,
}) => {
  return (
    <VerificationPageContainer
      sideImageUrl={submittedImage}
      handleSubmit={handleDocumentUpload}
      title={documentDetails?.document?.name}
      description={documentDetails?.document?.headerText}
      currentStep={4}
      onBack={() => {
        setSelectedDocument(null);
      }}
      backArrow={true}
    >
      <Stack spacing={2}>
        <DocumentTypeFieldUI
          selectedType={type}
          handleDocumentTypeChange={(e) => {
            setType(e.target.value);
          }}
          document={documentDetails?.document}
          onClick={documentTypeFieldOnClick}
        />
        <UploadFieldUI
          handleFileChange={handleFileChange}
          documentName={documentName}
          onClick={documentUploadFieldOnClick}
        />
      </Stack>
    </VerificationPageContainer>
  );
};

export default DocumentUploadFieldUI;
