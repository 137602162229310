import personalInfoImage from "../../../../../images/personal.png";
import usePersonalInfoFormInputs from "../molecules/usePersonalInfoFormInputs";

const usePersonalInfoForm = ({
  personalInfo,
  controllerIndex,
  setOwners,
  setControllerIndex,
  setValue,
}) => {
  const { handleSubmit, fieldConfig, errors } =
    usePersonalInfoFormInputs({
      personalInfo,
      controllerIndex,
      setOwners,
      setControllerIndex,
      setValue,
    });

  return {
    personalInfoImage,
    handleSubmit,
    fieldConfig,
    errors,
  };
};

export default usePersonalInfoForm;
