import  { useEffect, useState } from "react";
import LogoutPanel from '../atoms/logoutPanel';
import AccountStatusPanel from './../../functional/molecules/accountStatusPanel';

const SettingsLogic = () => {
    const [tabValue, setTabValue] = useState('1')
    ;
    const {
        requirements,
        profileStatus,
        isActive,
        dailogDetails,
        activeDailog,
         handleIconClick,
         handleDailogClose
      } = AccountStatusPanel();

    const {
        handleSignOut
    } = LogoutPanel();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
      };

    // const handleDailogClose = () => {
    // }
    
    return {
        tabValue,
        requirements,
        profileStatus,
        isActive,
        dailogDetails,
        activeDailog,
        handleIconClick,
        handleChange,
        handleSignOut,
        handleDailogClose
    }
}

export default SettingsLogic;