import React from "react";

import OwnerManagerUIInputs from "../molecules/ownerManagerUIInputs";
import SignUpPageContainer from "../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer";

function OwnerManagerUI({
  owners,
  onAdd,

  onOwnerClick,
  handleSubmit,
  navigateBackToController,
  handleDeleteClick,
}) {
  return (
    <>
      <SignUpPageContainer
      
        handleSubmit={handleSubmit}
        disabled={owners.length === 0}
        title={"All Owner"}
        description={
          "Add in every owner that owns at least 25% of the bussiness"
        }
        currentStep={3}
        onBack={navigateBackToController}
        backArrow={true}
      >
        <OwnerManagerUIInputs
          owners={owners}
          onAdd={onAdd}
          onOwnerClick={onOwnerClick}
          handleDeleteClick={handleDeleteClick}
        />
      </SignUpPageContainer>
    </>
  );
}

export default OwnerManagerUI;
