// AccountInfoPage.js
import React from "react";
import UserOptions from "../molecules/UserOptions";
import AccountInfoPageUI from "../../ui/page/AccountInfoPageUI";
import useAccountInfo from "../../functionality/page/main";
import SignInPageContainer from "../../../../components/pageStructures/authPages/combined/pages/signInPageContainer";


const SignInPage = () => {
  const { accountInfo, setAccountInfo, error, handleSubmit } = useAccountInfo();

  return (
    <SignInPageContainer  handleSubmit={handleSubmit}>
    <AccountInfoPageUI
      accountInfo={accountInfo}
      setAccountInfo={setAccountInfo}
      error={error}
   
    >
      <UserOptions /> {/* Passing UserOptions as children */}
    </AccountInfoPageUI>
    </SignInPageContainer>
  );
};

export default SignInPage;
