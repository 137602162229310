import { TextField } from "@mui/material";
import React from "react";
import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";

const DaysDialogUI = ({ open, handleClose, handleEnter, days, setDays }) => {
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleEnter}
      title="Enter Days"
    >
      <TextField
        autoFocus
        margin="dense"
        id="days"
        label="Number of Days"
        type="number"
        fullWidth
        variant="outlined"
        value={days}
        onChange={(e) => setDays(e.target.value)}
      />
    </CustomInputDialog>
  );
};

export default DaysDialogUI;
