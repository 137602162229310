import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectPendingBalance } from "./../../../../features/banking/bankSelectors";
import { selectBankData } from "./../../../../features/banking/bankSelectors";
import useInitializePlaid from "./plaid";
import { keyframes } from "@mui/system";

const moveUpDown = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(27px);
    }
`;

const BankLogic = () => {
    const pendingBalance = useSelector(selectPendingBalance);
    const bankData = useSelector(selectBankData);
    const hasBankAccount = bankData && bankData.accountNumber;
    const { open, ready, openModal } = useInitializePlaid();
    const [activeInfoDailog, setActiveInfoDailog] = useState(false)
    const [activeCardInfoDailog, setActiveCardInfoDailog] = useState(false)

    const [dots, setDots] = useState("");

    const handleAccountClick = () => {
      localStorage.removeItem("plaidLinkToken");
      localStorage.removeItem("plaidLinkTokenExpiry");
      ready && open();
    };

    useEffect(() => {
        if(openModal) {
            const interval = setInterval(() => {
            setDots((prev) => (prev.length < 8 ? prev + "." : ""));
            }, 350); // Adjust time to speed up or slow down

            return () => clearInterval(interval);
        }
      }, [openModal]);
    
    const handleInfoDailog = (cardInfo) => {
        if (activeInfoDailog) {
            setActiveInfoDailog(false);
            setActiveCardInfoDailog('')
        } else {
            switch(cardInfo) {
                case 'bankCard':
                    setActiveCardInfoDailog('bankCard')
                    setActiveInfoDailog(true)
                    return;
                case 'addBank':
                    setActiveCardInfoDailog('addBank')
                    setActiveInfoDailog(true)
                    return;
                case 'remainingBalance':
                    setActiveCardInfoDailog('remainingBalance')
                    setActiveInfoDailog(true)
                    return;
                default:
                    return;
            }
        }
    }
    
    return {
        bankData,
        dots,
        moveUpDown,
        hasBankAccount,
        pendingBalance,
        openModal,
        activeInfoDailog,
        activeCardInfoDailog,
        handleAccountClick,
        handleInfoDailog
    }
}

export default BankLogic;