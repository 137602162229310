import { Box, Typography } from "@mui/material";

const SuspendedPagePanelUI = ({ customerServiceEmail }) => {
  return (
    <Box sx={{ maxWidth: 500, mx: "auto", textAlign: "center", p: 3 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We regret to inform you that the verification process for your merchant
        account has not been successful. This could be due to a variety of
        factors related to the information provided or documentation submitted.
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We understand this may be disappointing and, in some cases, a mistake on
        our part. If you believe your account should have been verified, or if
        there are discrepancies in the information reviewed, we strongly
        encourage you to reach out to our customer support team directly at{" "}
        <strong>{customerServiceEmail}</strong>.
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Our team is here to assist you and ensure that any issues are resolved
        promptly. Thank you for your understanding and cooperation.
      </Typography>
    </Box>
  );
};

export default SuspendedPagePanelUI;
