import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../functions/apiFetchers/fullPageLoaders";
import { setStatus } from "../../../../../features/user/userSlice";
import { showAlert } from "../../../../../features/alerts/alertSlice";

const useAccountInfoInputs = () => {
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const profile_pk = location.state?.profile_pk;
  const email = location.state?.email;
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (event) => {
    const errorValidation = [
      { field: "username", type: "content", required: true },
      { field: "password", type: "password", required: true },
      {
        field: "profile_pk",
        type: "number",
        required: true,
        customMessage: "Please reverify email",
      },
    ];

    await fetchFeedback({
      endpoint: "merchant/account_info/",
      requestType: "POST",
      validationRules: errorValidation,
      data: { ...accountInfo, profile_pk, email },
      loaderMessage: "Creating Account...",
      auth: false,

      onSuccess: (responseData) => {
        localStorage.setItem("token", responseData.token);
        dispatch(setStatus("personalInfo"));
        navigate("/personal-info");
      },
      onError: (error) => {
        if (error.profile_pk) {
          navigate("/email");
          dispatch(
            showAlert({ type: "error", message: "please reverify your email" })
          );
        } else {
          setError(error);
        }
      },
    });
  };

  return {
    handleSubmit,
    navigate,
    accountInfo,
    setAccountInfo,
    error,
  };
};

export default useAccountInfoInputs;
