import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clavaaTerms, dwollaTerms } from './info/terms'; // Assuming terms.js 
import { showDialog } from '../../../../../features/dialog/dialogSlice';

const UseSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to set and show Clavaa terms in a dialog
  const showClavaaTerms = () => {
    dispatch(
      showDialog({
        dialogType: 'info',
        message: clavaaTerms.info, // Set the Clavaa terms subtitle as the message
        title: clavaaTerms.title, // Set the Clavaa terms title as the dialog title
      })
    );
  };

  // Function to set and show Dwolla terms in a dialog
  const showDwollaTerms = () => {
    dispatch(
      showDialog({
        dialogType: 'info',
        message: dwollaTerms.info, // Set the Dwolla terms subtitle as the message
        title: dwollaTerms.title, // Set the Dwolla terms title as the dialog title
      })
    );
  };

  // Function to navigate to the sign-in page
  const navigateToSignIn = () => {
    navigate('/sign-in'); // Adjust the route to the correct sign-in page route
  };

  // Return the functions to show terms and navigation function
  return {
    showClavaaTerms,
    showDwollaTerms,
    navigateToSignIn,
  };
};

export default UseSignUp;
