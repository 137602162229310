import { Box } from "@mui/material";
import DataDurationIndicator from "../molecules/dataDurationIndicatorUI";
import Tabs from "../molecules/tabsUI";

const DaysPanel = ({
  duration,
  setDialogOpen,
  dialogOpen,
  handleClose,
  handleEnter,
  days,
  setDays,
  infoBoxes,
  activeInfoDailog,
  handleInfoDailog,
  info,
  activeIndex,
}) => {
  return (
    <Box>
      <DataDurationIndicator
        duration={duration}
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        handleEnter={handleEnter}
        days={days}
        setDays={setDays}
      />
      <Tabs
        infoBoxes={infoBoxes}
        activeInfoDailog={activeInfoDailog}
        handleInfoDailog={handleInfoDailog}
        info={info}
        activeIndex={activeIndex}
      />
    </Box>
  );
};

export default DaysPanel;
