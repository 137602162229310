import React from "react";
import DocumentUploadFieldUIContainer from "../organisms/documentUploadFieldUIContainer";
import DocumentManagerUIContainer from "../organisms/documentManagerUIContainer";
import useDocument from "../../functional/page/useDocument";

const DocumentPage = () => {
  const {
    documentDetails,
    setSelectedDocument,
    handleDocumentUpload,
    type,
    setType,
    setFile,
    submittedImage,

    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,
    documentTypeFieldOnClick,
    documentUploadFieldOnClick,
    handleFileChange,
    documentName,
  } = useDocument();
  if (documentDetails) {
    return (
      <DocumentUploadFieldUIContainer
        documentDetails={documentDetails}
        setSelectedDocument={setSelectedDocument}
        handleDocumentUpload={handleDocumentUpload}
        type={type}
        setType={setType}
        setFile={setFile}
        submittedImage={submittedImage}
        documentUploadFieldOnClick={documentUploadFieldOnClick}
        documentTypeFieldOnClick={documentTypeFieldOnClick}
        handleFileChange={handleFileChange}
        documentName={documentName}
      />
    );
  }

  return (
    <DocumentManagerUIContainer
      submittedImage={submittedImage}
      onDocumentUpload={onDocumentUpload}
      isAllDocumentsUploaded={isAllDocumentsUploaded}
      documentList={documentList}
      setSelectedDocument={setSelectedDocument}
    />
  );
};

export default DocumentPage;
