import { useEffect, useState } from "react";
import validatePersonalDetails from "../helpers/personal/validate";

const usePersonalInfoFormInputs = ({
  personalInfo,
  controllerIndex,
  setOwners,
  setControllerIndex,
  setValue,
}) => {
  const [errors, setError] = useState("");

  const fieldConfig = [
    {
      type: "text",
      label: "First Name",
      name: "first_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      label: "Last Name",
      name: "last_name",
      grid: { xs: 12, sm: 6 },
    },
    { type: "text", label: "Title", name: "title", grid: { xs: 12, sm: 6 } },
    {
      type: "date",
      label: "Date of Birth",
      name: "date_of_birth",
      grid: { xs: 12, sm: 6 },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
    {
      type: "ssn",
      label: personalInfo.isOwner ? "SSN" : "Last 4 of SSN",
      name: "ssn",
      grid: { xs: 12 },
      props: { fullSSN: personalInfo.isOwner },
    },
  ];

  // erases the controller as a user if he was set since they are resetting it.

  useEffect(() => {
    if (controllerIndex) {
      setOwners((currentOwners) =>
        currentOwners.filter((owner) => owner.id !== controllerIndex)
      );
      setControllerIndex(null);
    }
  }, []);

  const handleSubmit = () => {
    const errors = validatePersonalDetails(personalInfo);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      return;
    }

    if (personalInfo.isOwner) {
      setOwners((prevState) => {
        const highestId =
          prevState.length > 0
            ? prevState.reduce(
                (maxId, owner) => Math.max(maxId, parseInt(owner.id, 10)),
                0
              )
            : 0;
        const newOwner = {
          ...personalInfo,
          id: highestId + 1,
        };

        setControllerIndex(highestId + 1);
        return [...prevState, newOwner];
      });
    }
    // This navigates to the next page
    setValue(1);
  };

  return {
    handleSubmit,
    fieldConfig,
    errors,
  };
};

export default usePersonalInfoFormInputs;
