import { useState } from "react";
import useDialog from "../atoms/useDialog";
import useDataDuration from "../molecules/useDataDuration";
import useTabs from "../molecules/useTabs";
import useTransactions from "../molecules/useTransactions";

const DashboardLogic = () => {
  const { duration, setDialogOpen, dialogOpen, handleSubmitDuration } =
    useDataDuration();
  const { open, handleClose, handleEnter, days, setDays } = useDialog({
    open: dialogOpen,
    setOpen: setDialogOpen,
    onSubmit: handleSubmitDuration,
  });
  const { infoBoxes } = useTabs();
  const [info, setInfo] = useState(null);
  const [activeInfoDailog, setActiveInfoDailog] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleInfoDailog = (index) => {
    if (activeInfoDailog) {
      setActiveIndex(-1);
      setInfo(false);
      setActiveInfoDailog(false);
    } else {
      setActiveIndex(index);
      setInfo(infoBoxes[index]);
      setActiveInfoDailog(true);
    }
    setActiveInfoDailog(!activeInfoDailog);
  };
  const { displayedTransactions, loading } = useTransactions();
  return {
    duration,
    setDialogOpen,
    dialogOpen,
    handleSubmitDuration,
    open,
    handleClose,
    handleEnter,
    days,
    setDays,
    infoBoxes,
    displayedTransactions,
    activeInfoDailog,
    handleInfoDailog,
    info,
    activeIndex,
    loading,
  };
};

export default DashboardLogic;
